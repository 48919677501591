(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('EquipeDialogController', EquipeDialogController);

    EquipeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'Equipe', 'Technicien','AlertService', 'GroupePlanning', 'Outil', '$q'];

    function EquipeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, Equipe, Technicien, AlertService, GroupePlanning, Outil, $q) {

        var vm = this;
        vm.date = $stateParams.date;
        vm.newEquipes = {};
        vm.clear = clear;
        vm.loadEquipes = loadEquipes;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.techniciens = [];
        vm.availableTechniciens = [];
        vm.colors = null;
        vm.addEquipe = addEquipe;
        vm.deleteEquipe = deleteEquipe;
        vm.updateEquipe = updateEquipe;
        vm.getTechnicienForTeam = getTechnicienForTeam;
        vm.copyActualEquipe = copyActualEquipe;
        vm.initNewEquipes = initNewEquipes;
        vm.adaptOutilForChef = adaptOutilForChef;
        vm.inEditingState = false;

        vm.dropdownSettings = {
            buttonClasses: 'form-control',
            displayProp: 'dropdownDisplay',
            idProperty: 'code',
            checkBoxes: true,
            showUncheckAll: false,
            showCheckAll: false,
            scrollableHeight: '200px',
            scrollable: true,
        };

        vm.dropdownTranslation = {
            buttonDefaultText: '-- outil --',
            selectionCount: ' outils sélectionnés'
        }

        loadAllData();
        defineColors();

        function loadAllData() {
            $q.all([
                loadTechniciens(vm.date),
                loadGroupesPlanning(),
                loadOutil(),
            ])
                .then(function() {
                    return loadEquipes();
                })
                .then(function() {
                    adaptAvailableTechniciens();
                })
        }

        function addEquipe(groupePlanningId) {
            var equipe = vm.newEquipes[groupePlanningId];

            equipe.binome = equipe.binome === ""? null : equipe.binome;

            //on va set les codes à la place des ids renvoyés par le dropdown
            if (equipe.outilIds && equipe.outilIds.length){
                var outilCodes = [];
                equipe.outilIds.forEach(function(outilObj) {
                    var outilCode;
                    for (var i = 0; i < vm.outils.length; i++) {
                        if (vm.outils[i].id == outilObj.id) {
                            outilCode = vm.outils[i].code;
                            break;
                        }
                    }
                    if (outilCode) {
                        outilCodes.push(outilCode);
                    }
                });

                equipe.outilCodes = outilCodes;
            }else {
                equipe.outilCodes = null;
            }

            equipe.outilIds = null;
            equipe.groupePlanningId = groupePlanningId;
            equipe.date = vm.date;

            updateNotWorkingTechniciens(equipe);
            vm.isSaving = true;
            Equipe.save(equipe, onSaveSuccess, onSaveError);
            vm.newEquipes[groupePlanningId] = { outilIds: [] };
        }

        function updateEquipe(equipe) {
            equipe.binome = equipe.binome === ""? null : equipe.binome;

            if (equipe.outils && equipe.outils.length) {
                var outilCodes = [];
                equipe.outils.forEach(function (outilObj) {
                    var outilCode;
                    for (var i = 0; i < vm.outils.length; i++) {
                        if (vm.outils[i].id == outilObj.id) {
                            outilCode = vm.outils[i].code;
                            break;
                        }
                    }
                    if (outilCode) {
                        outilCodes.push(outilCode);
                    }
                });

                equipe.outilCodes = outilCodes;
            }else {
                equipe.outilCodes = null;
            }

            equipe.outilIds = null;
            equipe.outils = null;

            Equipe.update(equipe).$promise.then(function() {
                deleteEquipeFromWorkingTechnicien(vm.oldEquipeBeforeEdit);
                updateNotWorkingTechniciens(equipe);
                loadEquipes();
            });
        }

        function deleteEquipe(equipe) {
            Equipe.delete({id: equipe.id}).$promise.then(function() {
                loadEquipes();
                deleteEquipeFromWorkingTechnicien(equipe);
                adaptAvailableTechniciens();
            });
        }

        function deleteEquipeFromWorkingTechnicien(equipe) {
            if (equipe.chef && vm.alreadyWorkingTechniciens.includes(equipe.chef)) {
                var index = vm.alreadyWorkingTechniciens.indexOf(equipe.chef);
                if (index !== -1) {
                    vm.alreadyWorkingTechniciens.splice(index, 1);
                }
            }
            if (equipe.binome && vm.alreadyWorkingTechniciens.includes(equipe.binome)) {
                var index = vm.alreadyWorkingTechniciens.indexOf(equipe.binome);
                if (index !== -1) {
                    vm.alreadyWorkingTechniciens.splice(index, 1);
                }
            }
        }

        function loadOutil() {
            return Outil.getEnabled({ }, function onSuccess(data, headers) {
                data.forEach(function(outil){
                    outil.dropdownDisplay = outil.code + ': ' + outil.name;
                });
                vm.outils = data;

            }, function onError(error) {
                AlertService.error(error.data.message);
            }).$promise;
        }

        function loadGroupesPlanning() {
            return GroupePlanning.getEnabled({ }, function onSuccess(data, headers) {
                vm.groupesPlanning = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            }).$promise;
        }

        function loadEquipes(){
            return Equipe.getByDate({
                    date: vm.date,
                },
                function onSuccess(data, headers) {
                    vm.equipes = {};
                    vm.alreadyWorkingTechniciens = new Set();

                    data.forEach(function(equipe) {
                        equipe.editing = false;

                        equipe.outilIds = [];
                        equipe.outils = [];
                        if (equipe.outilCodes && equipe.outilCodes.length) {
                            equipe.outilCodes.forEach(function(code) {
                                var outil = vm.outils.find(function(o) {
                                    return o.code === code;
                                });
                                if (outil) {
                                    equipe.outils.push(outil);
                                }
                            });
                        }

                        vm.alreadyWorkingTechniciens.add(equipe.chef);
                        if (equipe.binome) {
                            vm.alreadyWorkingTechniciens.add(equipe.binome);
                        }

                        var groupePlanningId = equipe.groupePlanningId;
                        if (groupePlanningId !== null) {
                            if (vm.equipes[groupePlanningId] === undefined) {
                                vm.equipes[groupePlanningId] = [];
                            }
                            vm.equipes[groupePlanningId].push(equipe);
                        }else {
                            if (vm.equipes['withoutGroupePlanning'] === undefined) {
                                vm.equipes['withoutGroupePlanning'] = [];
                            }
                            vm.equipes['withoutGroupePlanning'].push(equipe);
                        }
                    });

                    var array = [];
                    vm.alreadyWorkingTechniciens.forEach(function(item) {
                        array.push(item);
                    });

                    vm.alreadyWorkingTechniciens = array;
                },
                function onError(error) {
                    AlertService.error(error.data.message);
                }).$promise;
        }

        function updateNotWorkingTechniciens(newEquipe){
            if (newEquipe.chef && !vm.alreadyWorkingTechniciens.includes(newEquipe.chef)) {
                vm.alreadyWorkingTechniciens.push(newEquipe.chef);
            }
            if (newEquipe.binome && !vm.alreadyWorkingTechniciens.includes(newEquipe.binome)) {
                vm.alreadyWorkingTechniciens.push(newEquipe.binome);
            }

            adaptAvailableTechniciens();
        }

        function adaptAvailableTechniciens() {
            vm.availableTechniciens = vm.techniciens.filter(function(technicien) {
                return !vm.alreadyWorkingTechniciens.includes(technicien.displayname);
            });
        }

        function getTechnicienForTeam(selectedTech) {
            if(selectedTech){
                return vm.availableTechniciens.filter(function(technicien) { return technicien.displayname !== selectedTech; });
            }else{
                return vm.availableTechniciens;
            }
        }

        function adaptOutilForChef(equipe) {
            equipe.outilIds = vm.outils
                .filter(function(outil) {
                    return outil.technicien === equipe.chef;
                })
                .map(function(outil) {
                    return { id: outil.id };
                });
            console.log(equipe);
        }

        function copyActualEquipe(equipe) {
            vm.oldEquipeBeforeEdit = angular.copy(equipe);
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.equipe.id !== null) {
                Equipe.update(vm.equipe, onSaveSuccess, onSaveError);
            } else {
                Equipe.save(vm.equipe, onSaveSuccess, onSaveError);
            }
        }

        function loadTechniciens(date) {
            return Technicien.getAvailableForPlanning({ date: date }, function onSuccess(data, headers) {
                vm.techniciens = data;
            }).$promise;
        }

        function initNewEquipes(groupePlanningId) {
            //pour le dropdown des outils
            if (!vm.newEquipes[groupePlanningId]) {
                vm.newEquipes[groupePlanningId] = { outilIds: [] };
            }
        };

        function defineColors(){
            vm.colors = {
                Gris: {
                    title: "#8C8C8B",
                    base: "#BDBDBD",
                },
                Bleu: {
                    title: "#6590EB",
                    base: "#A5C0FA"
                },
                Vert: {
                    title: "#58BF64",
                    base: "#9BE8A5"
                },
                Rouge: {
                    title: "#F56464",
                    base: "#FCB1B1"
                },
                Jaune: {
                    title: "#FAE950",
                    base: "#FAEE87"
                },
                Violet: {
                    title: "#D159DE",
                    base: "#E797F0"
                },
                Orange: {
                    title: "#FFB061",
                    base: "#FFCA96"
                }
            };
        }

        function onSaveSuccess (result) {
            loadEquipes();
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
