(function() {
    'use strict';

    angular
        .module('tmgApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('resultat-technicien', {
                url: '/resultat-technicien?technicien&type&dateFrom&dateTo',
                parent: 'gestion',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Resultat technicien'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/resultat-technicien/resultat-technicien.html',
                        controller: 'ResultatTechnicienController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    dateFrom: null,
                    dateTo: null,
                    technicien: null,
                    type: null
                },
                resolve: {
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            dateFrom: $stateParams.dateFrom,
                            dateTo: $stateParams.dateTo,
                            technicien: $stateParams.technicien,
                            type: $stateParams.type,
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
