(function() {
    'use strict';
    angular
        .module('tmgApp')
        .factory('Equipe', Equipe);

    Equipe.$inject = ['$resource', 'DateUtils'];

    function Equipe ($resource, DateUtils) {
        var resourceUrl =  'api/equipes/:id';

        return $resource(resourceUrl, {id: '@id'}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertLocalDateFromServer(data.date);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                url:'api/equipes',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                }
            },
            'importEquipeMultiple' : {
                method: 'POST',
                url: 'api/equipes/importMultiple',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'processByFilter': {
                method: 'POST',
                url: 'api/equipes/processByFilter',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                }
            },
            'getByDate': {
                method: 'GET',
                url: 'api/equipes/get-by-date',
                params: {
                    date: '@date',
                },
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        for (var i = 0; i < data.length; i++) {
                            data[i].date = DateUtils.convertLocalDateFromServer(data[i].date);
                        }
                    }
                    return data;
                }
            },
            'copyToNextDay': {
                method: 'POST',
                url: 'api/equipes/copy-to-next-day',
                params: {
                    date: '@date',
                },
                isArray: true,
                transformResponse: function(data) {
                    if(data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'copyToDate': {
                method: 'POST',
                url: 'api/equipes/copy-to-date',
                params: {
                    date: '@date',
                    dateTarget: '@dateTarget'
                },
                isArray: true,
                transformResponse: function(data) {
                    if(data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'copyToAllWeek': {
                method: 'POST',
                url: 'api/equipes/copy-to-all-week',
                params: {
                    date: '@date',
                },
                isArray: true,
                transformResponse: function(data) {
                    if(data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getCurrentWeek': {
                method: 'GET',
                url: 'api/equipes/get-by-week',
                params: {
                    start: '@start',
                    end: '@end'
                },
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        for (var i = 0; i < data.length; i++) {
                            data[i].date = DateUtils.convertLocalDateFromServer(data[i].date);
                        }
                    }
                    return data;
                }
            },
            'getByDatesAndTech': {
                method: 'GET',
                url: 'api/equipes/get-by-dates-and-technicien',
                params: {
                    start: '@start',
                    end: '@end',
                    technicien: '@technicien'
                },
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        for (var i = 0; i < data.length; i++) {
                            data[i].dateStart = DateUtils.convertLocalDateFromServer(data[i].dateStart);
                            data[i].dateEnd = DateUtils.convertLocalDateFromServer(data[i].dateEnd);
                        }
                    }
                    return data;
                }
            },
        });
    }
})();
