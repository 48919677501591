(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('OutilDetailController', OutilDetailController);

    OutilDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Outil'];

    function OutilDetailController($scope, $rootScope, $stateParams, previousState, entity, Outil) {
        var vm = this;

        vm.outil = entity;
        vm.previousState = previousState.name;
    }
})();
