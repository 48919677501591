(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('GroupePlanningDetailController', GroupePlanningDetailController);

    GroupePlanningDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'GroupePlanning'];

    function GroupePlanningDetailController($scope, $rootScope, $stateParams, previousState, entity, GroupePlanning) {
        var vm = this;

        vm.groupePlanning = entity;
        vm.previousState = previousState.name;
    }
})();
