(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('IndicatorFileDetailController', IndicatorFileDetailController);

    IndicatorFileDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'IndicatorFile', '$state'];

    function IndicatorFileDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, IndicatorFile, $state) {
        var vm = this;

        vm.indicatorFile = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        vm.redownload = redownload;
        vm.disabled = disabled;

        var unsubscribe = $rootScope.$on('tmgApp:indicatorFileUpdate', function(event, result) {
            vm.indicatorFile = result;
        });
        $scope.$on('$destroy', unsubscribe);

		/*
        vm.csvJson=csvJSON(entity.csv);
        function csvJSON(csv){

        	  var lines=csv.split("\n");
        	  var result = [];
        	  var headers=lines[0].split(";");

        	  for(var i=0;i<lines.length;i++){

        	      var obj = {};
        	      var currentline=lines[i].split(";");

        	      for(var j=0;j<headers.length;j++){
        	          obj[headers[j]] = currentline[j];
        	      }

        	      result.push(obj);

        	  }
        	  return result;
        }
		*/

        function redownload(id) {
            IndicatorFile.redownload({id:id}, function(s){
                $state.go($state.current, $stateParams, {reload: true});
            }, function(error) {
                AlertService.error(error.data.message);
            });
        }

        function disabled(error) {
            if (error === false) {
                return "disabled";
            }
        }

		vm.download = function(){
			 IndicatorFile.downloadURL({
				id: vm.indicatorFile.id
            }, function(body){
				DataUtils.openURL(body.downloadURL);
			});
		};
    }
})();
