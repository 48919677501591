(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('ExportPraxedoDetailController', ExportPraxedoDetailController);

    ExportPraxedoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils','entity', 'ExportPraxedo'];

    function ExportPraxedoDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, ExportPraxedo) {
        var vm = this;

        vm.exportPraxedo = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('tmgApp:exportPraxedoUpdate', function(event, result) {
            vm.exportPraxedo = result;
        });
        $scope.$on('$destroy', unsubscribe);

		vm.download = function(){
			 ExportPraxedo.downloadURL({
				id: vm.exportPraxedo.id
            }, function(body){
				DataUtils.openURL(body.downloadURL);
			});
		};
    }
})();
