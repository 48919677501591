(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('GroupePlanningController', GroupePlanningController);

    GroupePlanningController.$inject = ['$state', 'GroupePlanning', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function GroupePlanningController($state, GroupePlanning, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;

        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        loadAll();

        function loadAll () {
            GroupePlanning.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),];
                result.push('id');
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.groupePlanning = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            });
        }
    }
})();
