(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('StatTechnicienController', StatTechnicienController);

    StatTechnicienController.$inject = ['$state','StatTechnicien','Technicien','$filter','pagingParams', 'Principal'];

    function StatTechnicienController($state, StatTechnicien,Technicien,$filter, pagingParams, Principal) {
        $(document).ready(function () {
            $('#filterTechnicien').select2();
            $('#filterTechnicien').on('select2:select', function (e) {
                vm.filterTechnicien = $('#filterTechnicien').val();
                vm.search();
            });
        });

        var vm = this;
        vm.isAdmin = false;

        Principal.identity().then(function(account) {
            if(account.authorities.indexOf("ROLE_ADMIN") === 1) {
                vm.isAdmin = true;
            }
        });

        vm.filterTechnicien = pagingParams.technicien;
        vm.statTechniciens = [];
        vm.filterComparaisonStatus = !pagingParams.comparaisonStatus ? ['CLOSE','CLOSE_MANUEL','CLOSE_NOT_FACTURABLE'] : pagingParams.comparaisonStatus;

        if (!pagingParams.dateFrom && !pagingParams.dateTo) {
            var date = new Date();
            changeDateMonth(date);
        }else {
            var date = new Date(pagingParams.dateFrom);
        }


        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.search=search;
        vm.transition = transition;
		vm.numberWithSpace = numberWithSpace;
        vm.getTotalPoints=getTotalPoints;
        vm.getTotalChiffre=getTotalChiffre;
        vm.filterDateTo = pagingParams.dateTo;
        vm.filterDateFrom = pagingParams.dateFrom;

        loadTechniciens();
        loadAll();

        function loadAll() {
            StatTechnicien.query({
                dateFrom: vm.filterDateFrom,
                dateTo: vm.filterDateTo,
                technicien: vm.filterTechnicien,
                comparaisonStatus: vm.filterComparaisonStatus
            },onSuccess, onError);
        }
        function onSuccess(data, headers) {
            vm.statTechniciens = data;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }

       function search(){
           transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                dateFrom: vm.filterDateFrom,
                dateTo: vm.filterDateTo,
                technicien: vm.filterTechnicien,
                comparaisonStatus: vm.filterComparaisonStatus
            });
        }

        function loadTechniciens(){
        	Technicien.technicienEnabledHistory ({ }, function onSuccess(data, headers) {
                vm.techniciens = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.previousMonth=function(){
            date.setMonth(date.getMonth()-1);
            changeDateMonth(date);

        }
        vm.nextMonth=function(){
            date.setMonth(date.getMonth()+1);
            changeDateMonth(date);
        }
        vm.previousDay=function (){
            date.setDate(date.getDate() - 1);
            var tmpDateTo = new Date(vm.filterDateTo);
            tmpDateTo.setDate(tmpDateTo.getDate() - 1);
            vm.filterDateFrom = $filter('date')(date,'yyyy-MM-dd');
            vm.filterDateTo = $filter('date')(tmpDateTo,'yyyy-MM-dd');
            transition();
        }
        vm.nextDay=function (){
            date.setDate(date.getDate() + 1);
            var tmpDate = new Date(vm.filterDateTo);
            tmpDate.setDate(tmpDate.getDate() + 1);
            vm.filterDateFrom = $filter('date')(date,'yyyy-MM-dd');
            vm.filterDateTo = $filter('date')(tmpDate,'yyyy-MM-dd');
            transition();
        }

        function changeDateMonth(date){
            var firstDayOfMonth=new Date(date.getFullYear(), date.getMonth(), 1);
            var lastDayOfMonth=new Date(date.getFullYear(), date.getMonth()+1, 0);
            vm.filterDateFrom = $filter('date')(firstDayOfMonth,'yyyy-MM-dd');//pagingParams.dateFrom;
            vm.filterDateTo = $filter('date')(lastDayOfMonth,'yyyy-MM-dd');//pagingParams.dateTo;
            transition();
        }

        function getTotalPoints(){
        	var totalPoints=0;
        	if(vm.statTechniciens){
        		vm.statTechniciens.forEach(function(statTechnicien) {
        			if(statTechnicien.totalPoints){
        				totalPoints+=statTechnicien.totalPoints;
        			}
        		});
        	}
        	return totalPoints.toFixed(2);
        }

		function numberWithSpace(x) {
			if(x){
			    var parts = x.toString().split(".");
			    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
			    return parts.join(".");
			}else{
				return null;
			}
		}

		function getTotalChiffre(){
        	var totalChiffre=0;
        	if(vm.statTechniciens){
        		vm.statTechniciens.forEach(function(statTechnicien) {
        			if(statTechnicien.price){
        				totalChiffre+=statTechnicien.price;
        			}
        		});
        	}
        	return numberWithSpace(totalChiffre.toFixed(2));
        }
    }
})();
