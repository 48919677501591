(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('IndicatorFileController', IndicatorFileController);

    IndicatorFileController.$inject = ['$state', 'DataUtils', 'IndicatorFile', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function IndicatorFileController($state, DataUtils, IndicatorFile, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.resetProcess = resetProcess;
        vm.resetByFilter = resetByFilter;
        vm.importIndicators = importIndicators;
        vm.importNonImportedIndicators = importNonImportedIndicators;
        vm.reimportFile = reimportFile;
        vm.redownload = redownload;
        vm.disabled = disabled;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        vm.search = search;
        vm.filterSearch = pagingParams.search;
        vm.filterAgence = pagingParams.agence;
        vm.filterCategory = pagingParams.category;
        vm.filterTypeIndicator = pagingParams.typeIndicator;
        vm.filterImported = pagingParams.imported;
        vm.filterIgnored = pagingParams.ignored;
        vm.filterError = pagingParams.error;

        loadAll();

        function loadAll () {
            IndicatorFile.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                search: vm.filterSearch,
                agence: vm.filterAgence,
                category: vm.filterCategory,
                typeIndicator: vm.filterTypeIndicator,
                imported: vm.filterImported,
                ignored: vm.filterIgnored,
                error: vm.filterError
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.indicatorFiles = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function search(){
        	vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.filterSearch,
                agence: vm.filterAgence,
                category: vm.filterCategory,
                typeIndicator: vm.filterTypeIndicator,
                imported: vm.filterImported,
                ignored: vm.filterIgnored,
                error: vm.filterError
            }, { reload: true });
        }

        function resetProcess(){
        	IndicatorFile.resetProcess({}, function(s){
        		transition();
        	}, function(error) {
                AlertService.error(error.data.message);
            });
        }

        function resetByFilter(){
            if (window.confirm("Êtes-vous de vouloir relancer l'import des fichiers en fonction des critères sélectionnés")) {
                IndicatorFile.resetImportFromIndicatorFilter({
                    search: vm.filterSearch,
                    agence: vm.filterAgence,
                    category: vm.filterCategory,
                    typeIndicator: vm.filterTypeIndicator,
                    imported: vm.filterImported,
                    ignored: vm.filterIgnored,
                    error: vm.filterError
                }, function (s){
                    transition();
                }, function (error) {
                    AlertService.error(error.data.message);
                });
            }
        }

         function importIndicators(){
        	IndicatorFile.importIndicators({}, function(s){
        		transition();
        	}, function(error) {
                AlertService.error(error.data.message);
            });
        }

        function reimportFile(id) {
            IndicatorFile.reimportFile({id:id}, function(s){
                transition();
            }, function(error) {
                AlertService.error(error.data.message);
            });
        }

        function redownload(id) {
            IndicatorFile.redownload({id:id}, function(s){
                transition();
            }, function(error) {
                AlertService.error(error.data.message);
            });
        }

        function disabled(error) {
            if (error === false) {
                return "disabled";
            }
        }

        function importNonImportedIndicators(){
            IndicatorFile.importNonImportedIndicators({}, function(s){
                transition();
            }, function(error) {
                AlertService.error(error.data.message);
            });
        }
    }
})();
