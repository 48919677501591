(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('AstreintePlanifieDialogController', AstreintePlanifieDialogController);

    AstreintePlanifieDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$uibModal', 'entity', 'AstreintePlanifie', 'Technicien', '$state', 'Astreinte', 'AlertService'];

    function AstreintePlanifieDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $uibModal,entity, AstreintePlanifie, Technicien, $state, Astreinte, AlertService) {
        $(document).ready(function () {
            $('#field_technicien').select2();
            $('#field_technicien').on('select2:select', function (e) {
                vm.astreintePlanifie.technicien = $('#field_technicien :selected').val();
            });
        });
        var vm = this;

        vm.astreintePlanifie = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.deleteAstreintePlanified = deleteAstreintePlanified;
        vm.frequenceActive = false;
        vm.isDatePast = isDatePast;
        vm.astreinteHours = 7;
        vm.createAstreinteRealise = createAstreinteRealise;

        loadTechniciens();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.astreintePlanifie.id !== null) {
                AstreintePlanifie.update(vm.astreintePlanifie, onSaveSuccess, onSaveError);
            } else {
                AstreintePlanifie.save(vm.astreintePlanifie, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('tmgApp:astreintePlanifieUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function createAstreinteRealise(){
            Astreinte.createFromAstreintePlanifie({astreintePlanifie: vm.astreintePlanifie, hours: vm.astreinteHours}, function onSuccess(data, headers) {
                $uibModalInstance.close(true);
                $state.reload();
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function isDatePast(){
            var date = new Date();
            var dateAstreinte = new Date(vm.astreintePlanifie.date);
            return dateAstreinte < date;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function loadTechniciens(){
            Technicien.technicienEnabled({ }, function onSuccess(data, headers) {
                vm.techniciens = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function deleteAstreintePlanified(id) {
            $uibModal.open({
                templateUrl: 'app/entities/astreinte-planifie/astreinte-planifie-delete-dialog.html',
                controller: 'AstreintePlanifieDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['AstreintePlanifie', function(AstreintePlanifie) {
                        return AstreintePlanifie.get({id : id}).$promise;
                    }]
                }
            }).result.then(function () {
                $uibModalInstance.close(true);
                $state.reload();
            });
        }
    }
})();
