(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('AstreintePlanifieDetailController', AstreintePlanifieDetailController);

    AstreintePlanifieDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AstreintePlanifie', 'Principal'];

    function AstreintePlanifieDetailController($scope, $rootScope, $stateParams, previousState, entity, AstreintePlanifie, Principal) {
        var vm = this;

        vm.astreintePlanifie = entity;
        vm.previousState = previousState.name;
        vm.isAccountProperty = false;

        Principal.identity().then(function(account) {
            if (account.technicien) {
                vm.isAccountProperty = account.technicien.displayname === vm.astreintePlanifie.technicien
            }else if(account.authorities.indexOf("ROLE_ADMIN") !== -1) {
                vm.isAccountProperty = true;
            }
        });

        var unsubscribe = $rootScope.$on('tmgApp:astreintePlanifieUpdate', function(event, result) {
            vm.astreintePlanifie = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
