(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('TechnicienDialogController', TechnicienDialogController);

    TechnicienDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Technicien'];

    function TechnicienDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Technicien) {
        var vm = this;

        vm.technicien = entity;
        vm.clear = clear;
        vm.save = save;
        vm.addAlias = addAlias;
        vm.removeAlias = removeAlias;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.technicien.id !== null) {
                Technicien.update(vm.technicien, onSaveSuccess, onSaveError);
            } else {
                Technicien.save(vm.technicien, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('tmgApp:technicienUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

         function addAlias() {
          	if(vm.technicien.aliases && vm.technicien.aliases.length>0){
          		if(vm.technicien.aliases[vm.technicien.aliases.length-1]!=''){
            		vm.technicien.aliases.push('');
            	}
            }else{
            	//Tableau vide
          		vm.technicien.aliases=[''];
          	}
        }

        function removeAlias(index){
         	vm.technicien.aliases.splice(index,1);
        }

    }
})();
