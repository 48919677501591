(function() {
    'use strict';
    angular
        .module('tmgApp')
        .factory('SfrFile', SfrFile);

    SfrFile.$inject = ['$resource', 'DateUtils'];

    function SfrFile ($resource, DateUtils) {
        var resourceUrl =  'api/sfr-files/:id';

        return $resource(resourceUrl, {id: '@id'}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertLocalDateFromServer(data.date);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                }
            },
            'process': { 
            	url:'api/sfr-files/:id/process',
            	method: 'PUT',
            	hasBody:false
            }
        });
    }
})();
