(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('AstreinteDeleteController',AstreinteDeleteController);

    AstreinteDeleteController.$inject = ['$uibModalInstance', 'entity', 'Astreinte'];

    function AstreinteDeleteController($uibModalInstance, entity, Astreinte) {
        var vm = this;

        vm.astreinte = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Astreinte.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
