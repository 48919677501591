(function() {
    'use strict';
    angular
        .module('tmgApp')
        .factory('ExportPraxedo', ExportPraxedo);

    ExportPraxedo.$inject = ['$resource', 'DateUtils'];

    function ExportPraxedo ($resource, DateUtils) {
        var resourceUrl =  'api/export-praxedos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertLocalDateFromServer(data.date);
                        data.creationDate = DateUtils.convertDateTimeFromServer(data.creationDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                }
            }
            ,'processMails': {
            	url:'api/export-praxedos/processMails',
            	method: 'PUT'
            }
            ,'resetProcess': {
            	url:'api/export-praxedos/resetProcess',
            	method: 'PUT'
            }
            ,'resetProcessPraxedo': {
                url:'api/export-praxedos/resetProcessPraxedo',
                method: 'PUT'
            }
            ,'resetProcessById':{
            	url:'api/export-praxedos/:id/resetProcess',
            	method: 'PUT',
            	hasBody:false,
                params: {id: "@id"}
            }
            ,'downloadURL':{
            	url:'api/export-praxedos/:id/file',
            	method: 'GET',
            	hasBody:false
            },
            'saveNewFile': {
                url:'api/export-praxedos/newFile',
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getLastImports': {
                url:'api/export-praxedos/lastImports',
                method: 'GET',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
