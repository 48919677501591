(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('ConfigDeleteController',ConfigDeleteController);

    ConfigDeleteController.$inject = ['$uibModalInstance', 'entity', 'Config'];

    function ConfigDeleteController($uibModalInstance, entity, Config) {
        var vm = this;

        vm.config = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Config.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
