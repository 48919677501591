(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('ValorisationDialogController', ValorisationDialogController);

    ValorisationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$uibModal', 'entity', 'Valorisation', 'Technicien'];

    function ValorisationDialogController($timeout, $scope, $stateParams, $uibModalInstance, $uibModal, entity, Valorisation, Technicien) {
        $(document).ready(function () {
            $('#field_technicien').select2();
            $('#field_technicien').on('select2:select', function (e) {
                vm.valorisation.technicien = $(this).val();
            });
        });
        var vm = this;
        vm.valorisation = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.deleteValorisation = deleteValorisation;

        loadTechniciens();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.valorisation.id !== null) {
                Valorisation.update(vm.valorisation, onSaveSuccess, onSaveError);
            } else {
                Valorisation.save(vm.valorisation, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('tmgApp:valorisationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function loadTechniciens() {
        	Technicien.technicienEnabled({ }, function onSuccess(data, headers) {
                vm.techniciens = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function deleteValorisation(id) {
            $uibModal.open({
                templateUrl: 'entities/valorisation/valorisation-delete-dialog.html',
                controller: 'ValorisationDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['Valorisation', function (Valorisation) {
                        return Valorisation.get({id: id}).$promise;
                    }]
                }
            }).result.then(function () {
                $uibModalInstance.close(true);
                $scope.go($state.current, {}, {reload: true});
            });
        }
    }
})();
