(function() {
    'use strict';
    angular
        .module('tmgApp')
        .factory('IndicatorFile', IndicatorFile);

    IndicatorFile.$inject = ['$resource'];

    function IndicatorFile ($resource) {
        var resourceUrl =  'api/indicator-files/:id';

        return $resource(resourceUrl, {id: '@id'}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
            ,'importIndicators': {
            	url:'api/indicator-files/importIndicators',
            	method: 'PUT'
            },
            'resetImportFromIndicatorFilter' : {
                url: 'api/indicator-files/reimportFileIndicator',
                method: 'GET'
            },
            'importNonImportedIndicators': {
                url: 'api/indicator-files/importNonImportedIndicators',
                method: 'PUT'
            },
            'reimportFile': {
                url:'api/indicator-files/resetProcessFile/:id',
                method: 'PUT'
            },
            'redownload': {
                url:'api/indicator-files/redownload/:id',
                method: 'PUT'
            }
            ,'downloadURL':{
            	url:'api/indicator-files/:id/file',
            	method: 'GET',
            	hasBody:false
            }
        });
    }
})();
