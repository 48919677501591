(function() {
    'use strict';
    angular
        .module('tmgApp')
        .controller('CalendarController', CalendarController);

    CalendarController.$inject = ['$state','$uibModal','AlertService','Conges', 'Calendar', 'Principal', 'pagingParams', 'Config', 'GroupePlanning', 'Equipe', '$q'];

    function CalendarController ($state,$uibModal,AlertService, Conges, Calendar, Principal, pagingParams, Config, GroupePlanning, Equipe, $q) {
        //Properties
        var vm = this;
        var isAdmin = false;
        vm.listTechniciens = [];
        vm.existingMonth = moment.months();
        vm.ApiResponseTab = [];
        vm.userEditing = {
            name : "",
        };
        vm.tabCalendar = [];
        vm.configTabLine = [];
        vm.selectedMonth = new Date(pagingParams.dateCalendar);
        vm.currentDate = new Date();
        vm.groupesPlanning = [];
        vm.equipes = [];

        //Functions
        Principal.hasAuthority("ROLE_ADMIN").then(function (result) {
            isAdmin = result;
            loadAllData();
        });

        vm.updateMonth = updateMonth;
        vm.openModalNewAstreinte = openModalNewAstreinte;
        vm.openModalNewAstreintePlanifie = openModalNewAstreintePlanifie;
        vm.openModalNewConge = openModalNewConge;
        vm.editAstreinte = editAstreinte;
        vm.editAstreintePlanified = editAstreintePlanified;
        vm.editConges = editConges;
        vm.numberOfDaysForMonth = numberOfDaysForMonth;
        vm.defineTdColor = defineTdColor;
        vm.getStrDateForNumDay = getStrDateForNumDay;
        vm.hightlightDayAndBorderWeek = hightlightDayAndBorderWeek;
        vm.numberOfWeek = numberOfWeek;
        vm.colspanCalcul = colspanCalcul;
		vm.rowClass = rowClass;
        vm.hexToRgba = hexToRgba;

        function loadAllData() {
            $q.all([
                loadEquipes(),
                loadAllEventCalendar(),
                loadGroupePlanning()
            ]).then(function() {
                defineColors();
            });
        }
        getConfig();

        function loadGroupePlanning(){
            if(isAdmin){
                return GroupePlanning.query({ }, function onSuccess(data, headers) {
                    vm.groupesPlanning = data;
                }, function onError(error) {
                    AlertService.error(error.data.message);
                }).$promise;
            }
        }

        function loadEquipes() {
            if(isAdmin){
                var firstDayOfMonth = new Date(vm.selectedMonth.getFullYear(), vm.selectedMonth.getMonth(), 1);
                firstDayOfMonth.setHours(firstDayOfMonth.getHours() + 2);
                var lastDayOfMonth = new Date(vm.selectedMonth.getFullYear(), vm.selectedMonth.getMonth() + 1, 0);
                lastDayOfMonth.setHours(lastDayOfMonth.getHours() + 2);
                return Equipe.getCurrentWeek({
                    start: firstDayOfMonth.toISOString().split('T')[0],
                    end: lastDayOfMonth.toISOString().split('T')[0]
                },onSuccess,onError).$promise;

                function onSuccess(data, headers) {
                    vm.equipes = data;
                };
                function onError(error) {
                    AlertService.error(error.data.message);
                };
            }
        }

        function defineColors(){
            vm.colors = {
                Gris: {
                    title: "#8C8C8B",
                    base: "#BDBDBD",
                },
                Bleu: {
                    title: "#6590EB",
                    base: "#A5C0FA"
                },
                Vert: {
                    title: "#58BF64",
                    base: "#9BE8A5"
                },
                Rouge: {
                    title: "#F56464",
                    base: "#FCB1B1"
                },
                Jaune: {
                    title: "#FAE950",
                    base: "#FAEE87"
                },
                Violet: {
                    title: "#D159DE",
                    base: "#E797F0"
                },
                Orange: {
                    title: "#FFB061",
                    base: "#FFCA96"
                }
            };

            //ajouter les couleurs dans vm.tabCalendar
            var groupesMapping = {};
            vm.groupesPlanning.forEach(function(oneGroupe) {
                groupesMapping[oneGroupe.id] = oneGroupe;
            });

            //mapper les equipes par technicien et date pour ameliorer les perfs
            var techDateMapping = {};
            vm.equipes.forEach(function(equipe) {
                var technicienKey1 = equipe.chef + "_" + equipe.date.toDateString();
                techDateMapping[technicienKey1] = groupesMapping[equipe.groupePlanningId].color;

                if (equipe.binome) {
                    var technicienKey2 = equipe.binome + "_" + equipe.date.toDateString();
                    techDateMapping[technicienKey2] = groupesMapping[equipe.groupePlanningId].color;
                }
            });

            vm.tabCalendar.forEach(function(oneTech) {
                //le technicien peut etre null

                if (oneTech[0].technicienName !== null) {
                    var technicienName = oneTech[0].technicienName;
                    oneTech.forEach(function(oneDay) {
                        var key = technicienName + "_" + new Date(oneDay.date).toDateString();
                        if (techDateMapping[key]) {
                            //= le technicien appartient à une equipe pour ce jour
                            oneDay.color = techDateMapping[key];
                        }
                    });
                }
            });

        }

        function hexToRgba(hex, opacity) {
            var bigint = parseInt(hex.slice(1), 16);
            var r = (bigint >> 16) & 255;
            var g = (bigint >> 8) & 255;
            var b = bigint & 255;
            return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
        }

        function createTabCalendar() {
            for (var user=0; user < vm.listTechniciens.length; user++ ) {
                vm.tabCalendar[user] = [];
                for (var day = 0; day < numberOfDaysForMonth(vm.selectedMonth); day++) {
                    var date = new Date(vm.selectedMonth);
                    date.setDate(day+1);
                    vm.tabCalendar[user][day] = {
                        astreinteId: null,
                        astreintePlanifie: null,
                        conges : [],
                        date: moment(date).format('YYYY-MM-DD'),
                        technicienName: null
                    };
                }
            }
            fillTabCalendar();
        }

        function fillTabCalendar() {
            for (var user = 0; user < vm.listTechniciens.length; user++) {
                for (var day = 0; day < vm.tabCalendar[user].length; day++) {
                    //ajouter le nom de tous les techniciens pour pouvoir afficher les couleurs des equipes
                    vm.tabCalendar[user][day].technicienName = vm.listTechniciens[user];
                    for (var responseAPI = 0; responseAPI < vm.ApiResponseTab[user].length; responseAPI++) {
                        if (vm.ApiResponseTab[user][responseAPI].date === vm.tabCalendar[user][day].date) {
                            vm.tabCalendar[user][day] = vm.ApiResponseTab[user][responseAPI];
                        }else {
                            vm.tabCalendar[user][day].technicienName = vm.listTechniciens[user];
                        }
                    }
                }
            }
        }

        function updateMonth(arg) {
            if (arg === "prev")
                vm.selectedMonth = previousMonth(vm.selectedMonth);
            if (arg === "next")
                vm.selectedMonth = nextMonth(vm.selectedMonth);
            if (arg === "actual")
                vm.selectedMonth = goToCurrent(vm.selectedMonth);
            pagingParams.dateCalendar = moment(vm.selectedMonth).format("YYYY-MM-DD");
            $state.transitionTo($state.$current, {
                dateCalendar: pagingParams.dateCalendar
            });
        }

        function nextMonth(date) {
            return new Date(date.setMonth(date.getMonth()+1));
        }

        function previousMonth(date) {
            return new Date(date.setMonth(date.getMonth()-1));
        }

        function numberOfDaysForMonth(date) {
            date = new Date(date);
            var d = new Date(date.getFullYear(), date.getMonth()+1, 0);
            return d.getDate();
        }

        function goToCurrent() {
            vm.selectedMonth = new Date();
            return vm.selectedMonth;
        }

        function openModalNewAstreintePlanifie() {
            $uibModal.open({
                templateUrl: 'app/entities/astreinte-planifie/astreinte-planifie-dialog.html',
                controller: 'AstreintePlanifieDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            technicien: null,
                            date: null,
                            id: null
                        };
                    }
                }
            }).result.then(function() {
                $state.go('calendar', null, { reload: 'calendar' });
            }, function() {
                $state.go('calendar');
            });
        }

        function editAstreintePlanified(id) {
            if(isAdmin) {
                $uibModal.open({
                    templateUrl: 'app/entities/astreinte-planifie/astreinte-planifie-dialog.html',
                    controller: 'AstreintePlanifieDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['AstreintePlanifie', function (AstreintePlanifie) {
                            return AstreintePlanifie.get({id: id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    $state.go('calendar', null, {reload: 'calendar'});
                }, function () {
                    $state.go('calendar');
                });
            }
        }

        function openModalNewAstreinte() {
            $uibModal.open({
                templateUrl: 'app/entities/astreinte/astreinte-dialog.html',
                controller: 'AstreinteDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            technicien: null,
                            date: null,
                            hours: null,
                            id: null
                        };
                    }
                }
            }).result.then(function() {
                $state.go('calendar', null, { reload: 'calendar' });
            }, function() {
                $state.go('calendar');
            });
        }

        function editAstreinte(id) {
            if(isAdmin) {
                $uibModal.open({
                    templateUrl: 'app/entities/astreinte/astreinte-dialog.html',
                    controller: 'AstreinteDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Astreinte', function (Astreinte) {
                            return Astreinte.get({id: id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    $state.go('calendar', null, {reload: 'calendar'});
                }, function () {
                    $state.go('calendar');
                });
            }
        }

        function editConges(id) {
            if(isAdmin) {
                $uibModal.open({
                    templateUrl: 'app/entities/conges/conges-dialog.html',
                    controller: 'CongesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Conges', function(Conges) {
                            return Conges.get({id : id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('calendar', null, { reload: 'calendar' });
                }, function() {
                    $state.go('calendar');
                });
            }
        }

        function openModalNewConge() {
            $uibModal.open({
                templateUrl: 'app/entities/conges/conges-dialog.html',
                controller: 'CongesDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            technicien: null,
                            date: null,
                            dateEnd: null,
                            paid: false,
                            id: null
                        };
                    }
                }
            }).result.then(function() {
                $state.go('calendar', null, { reload: 'calendar' });
            }, function() {
                $state.go('calendar');
            });
        }

        function getConfig() {
            Config.getByName({name: "calendarRowSeparator"}, onSuccess, onError);

            function onSuccess(data) {
                vm.configTabLine = data.value.split(',');
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadAllEventCalendar() {
            var date = new Date(pagingParams.dateCalendar);
            var dateFrom = new Date(date.getFullYear(), date.getMonth(), 1);
            dateFrom.setHours(dateFrom.getHours() + 2);
            var dateTo = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            dateTo.setHours(dateTo.getHours() + 2);
            var calendarFilter = {
                dateFrom: dateFrom.toISOString().split('T')[0],
                dateTo: dateTo.toISOString().split('T')[0]
            };

            return Calendar.query(calendarFilter, onSuccess,onError).$promise;

            function onSuccess(data) {
                delete data.$promise;
                delete data.$resolved;
                vm.listTechniciens = Object.keys(data);
                vm.ApiResponseTab = Object.values(data);
                createTabCalendar();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function defineTdColor(event, jour) {
            var classTd = "";
             if (event.astreinteId != null) {
                 classTd+= " tdYellow";
             } else if (event.conges[0] != null) {
				if(!event.conges[0].validate){
					classTd+= " tdRed";
				}else{
                 switch (event.conges[0].status) {
                     case "AT" : classTd+= " tdPink"; break;
                     case "CHP" : classTd+= " tdGrey"; break;
                     case "CP" : classTd+= " tdBlue"; break;
                     case "JR": classTd += " tdGreen"; break;
                     case "JD": classTd += " tdOrange"; break;
                     case "GD": classTd += " tdPurple"; break;
                 }
				}
             } else if (event.astreintePlanifie !== null ) {
                 if (event.astreintePlanifie[0] !== undefined ) {
                     switch (event.astreintePlanifie[0].type) {
                         case "CUIVRE": return "tdGreen";
                         case "FIBRE_CHEF_EQUIPE_BINOME": return "tdYellowChef";
                         case "FIBRE_BINOME": return "tdBeigeBinome";
                         case "FIBRE_CHEF_MONOME": return "tdLightBrown";
                     }
                 }
             }
            var date = new Date(vm.selectedMonth);
            date.setDate(jour);
            if(moment(date).isFerie()){
                classTd+= " tdGreySunday";
            }
            if (moment(date).isoWeekday() === 1 ) {
                classTd+= " borderLeft";
            }else if (moment(date).isoWeekday() === 7 ){
                classTd+= " borderRight tdGreySunday";
            }
             return classTd;
        }

        function numberOfWeek(jour) {
            var date = new Date(vm.selectedMonth);
            date.setDate(jour);
            if (moment(date).isoWeekday() === 4 ) {
                return moment(date).weeks();
            }
        }

        function colspanCalcul(day) {
            var date = new Date(vm.selectedMonth);
            date.setDate(day);

        }

        function getStrDateForNumDay(nbDay) {
            var date = new Date(vm.selectedMonth);
            date.setDate(nbDay);
            return moment(date).format('dd');
        }

        function hightlightDayAndBorderWeek($index) {
            var classTd = null;
            var date = new Date(vm.selectedMonth);
            if (vm.currentDate.getDate() === $index+1 && vm.currentDate.getMonth() === vm.selectedMonth.getMonth()) {
             classTd = "hightlightDayCalendar";
            }
            date.setDate($index+1);
            if (moment(date).isoWeekday() === 1 ) {
                classTd+= " borderLeft";
            }else if (moment(date).isoWeekday() === 7 ){
                classTd+= " borderRight";
            }
            return classTd;
        }

        function rowClass(key) {
            var classTr = null;
            if (vm.configTabLine.includes((key+1).toString())) {
                classTr= "bottomBorderBolder";
            }
            return classTr;
        }

    }

})();
