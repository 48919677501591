(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('InterventionCommentaryDialogController', InterventionCommentaryDialogController);

    InterventionCommentaryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Intervention'];

    function InterventionCommentaryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Intervention) {
        var vm = this;

        vm.intervention = entity;
        vm.listCommentary = null;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.intervention.id !== null) {
                Intervention.addCommentary({id: vm.intervention.id, commentary: vm.listCommentary}, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('tmgApp:intervention-detail({id:'+ vm.intervention.id + '})', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
