(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('AstreintePlanifieNotWorkController', AstreintePlanifieNotWorkController);

    AstreintePlanifieNotWorkController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$uibModal', 'entity', 'AstreintePlanifie', 'Technicien', '$state'];

    function AstreintePlanifieNotWorkController ($timeout, $scope, $stateParams, $uibModalInstance, $uibModal,entity, AstreintePlanifie, Technicien, $state) {
        var vm = this;

        vm.astreintePlanifie = entity;
        vm.clear = clear;
        vm.save = save;

        loadTechniciens();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.astreintePlanifie.id !== null) {
                AstreintePlanifie.asknotwork(vm.astreintePlanifie, onSaveSuccess, onSaveError);
            } else {
                AstreintePlanifie.save(vm.astreintePlanifie, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('tmgApp:astreintePlanifieUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function loadTechniciens(){
            Technicien.query({ }, function onSuccess(data, headers) {
                vm.techniciens = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }
    }
})();
