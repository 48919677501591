(function() {
    'use strict';

    angular
        .module('tmgApp')
        .directive('twoMonthsAdvance', twoMonthsAdvance);

    function twoMonthsAdvance () {
        var directive = {
            restrict: 'A',
            require: '?ngModel',
            link: linkFunc,
        };

        return directive;

        function linkFunc (scope, element, attrs, ngModel) {

            if (!ngModel) {
                return;
            }

            ngModel.$validators.twoMonthsAdvance = function (modelValue) {
                if (ngModel.$isEmpty(modelValue)){
                    return true;
                } else {
                    const MINIMUM_NOTICE_PERIOD_IN_MONTHS = 2;
                    const today = new Date();
                    var earliestAllowedLeaveDate = new Date(today.getFullYear(), today.getMonth() + MINIMUM_NOTICE_PERIOD_IN_MONTHS, today.getDate());
                    var inputDate = new Date(modelValue);

                    return inputDate >= earliestAllowedLeaveDate;
                }
            };
        }

    }

})();
