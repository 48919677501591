(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('StatFacturationMensuelController', StatFacturationMensuelController);

    StatFacturationMensuelController.$inject = ['StatFacturationMensuel','$filter'];

    function StatFacturationMensuelController(StatFacturationMensuel,$filter) {

        var vm = this;

        vm.statFacturationMensuels = [];
        
        var date = new Date();
    	changeDateMonth(date);
        
        vm.search = search;
        
        var date = new Date();
        var firstDayOfMonth=new Date(date.getFullYear(), date.getMonth(), 1);
        vm.filterDateFrom = $filter('date')(firstDayOfMonth,'yyyy-MM-dd');//pagingParams.dateFrom;
        vm.filterDateTo = null;//pagingParams.dateTo;


        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        
        loadAll();

        function loadAll() {
            StatFacturationMensuel.query({
                dateFrom: vm.filterDateFrom,
                dateTo: vm.filterDateTo
            },function(result) {
                vm.statFacturationMensuels = result;
                vm.searchQuery = null;
            });
        }
        
        function search(){
        	loadAll();
        }
        
        vm.datePickerOpenStatus.creationDate = false;
        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        
        vm.previousMonth=function(){
        	date.setMonth(date.getMonth()-1);
        	changeDateMonth(date);
        }
        vm.nextMonth=function(){
        	date.setMonth(date.getMonth()+1);
        	changeDateMonth(date);
        }
        
        function changeDateMonth(date){
        	var firstDayOfMonth=new Date(date.getFullYear(), date.getMonth(), 1);
	        var lastDayOfMonth=new Date(date.getFullYear(), date.getMonth()+1, 0);
	        vm.filterDateFrom = $filter('date')(firstDayOfMonth,'yyyy-MM-dd');//pagingParams.dateFrom;
	        vm.filterDateTo = $filter('date')(lastDayOfMonth,'yyyy-MM-dd');//pagingParams.dateTo;
	        search();
        }
    }
})();
