(function() {
    'use strict';

    angular
        .module('tmgApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('intervention', {
            parent: 'technicienMenu',
            url: '/intervention?page&sort&search&status&comparaisonStatus&dateFrom&dateTo&technicien&technicienEquipe&technicienRole&tailleEquipe&constructelFilename&dateMailImport&filename&bonDeCommande',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'tmgApp.intervention.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/intervention/interventions.html',
                    controller: 'InterventionController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'date,desc',
                    squash: true
                },
                search: null,
                status: null,
                comparaisonStatus: null,
                dateFrom: null,
                dateTo: null,
                technicien: null,
                technicienEquipe: null,
                tailleEquipe: null,
				technicienRole: null,
                constructelFilename: null,
                dateMailImport:null,
                filename: null,
                bonDeCommande: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        status: $stateParams.status,
                        comparaisonStatus: $stateParams.comparaisonStatus,
                        dateFrom: $stateParams.dateFrom,
                        dateTo: $stateParams.dateTo,
                        technicien: $stateParams.technicien,
                        technicienEquipe: $stateParams.technicienEquipe,
						technicienRole: $stateParams.technicienRole,
                        tailleEquipe: $stateParams.tailleEquipe,
                        constructelFilename: $stateParams.constructelFilename,
                        dateMailImport: $stateParams.dateMailImport,
                        filename: $stateParams.filename,
                        bonDeCommande: $stateParams.bonDeCommande,
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('intervention');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('intervention-detail', {
            parent: 'intervention',
            url: '/intervention/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'tmgApp.intervention.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/intervention/intervention-detail.html',
                    controller: 'InterventionDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('intervention');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Intervention', function($stateParams, Intervention) {
                    return Intervention.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'intervention',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('intervention-detail.edit', {
            parent: 'intervention-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/intervention/intervention-dialog.html',
                    controller: 'InterventionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xl',
                    windowClass: 'modal-xl',
                    resolve: {
                        entity: ['Intervention', function(Intervention) {
                            return Intervention.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('intervention-detail.commentary', {
            parent: 'intervention-detail',
            url: '/detail/commentary',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/intervention/intervention-commentary-dialog.html',
                    controller: 'InterventionCommentaryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Intervention', function(Intervention) {
                            return Intervention.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('intervention-detail', null, { reload: 'intervention' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('intervention.new', {
            parent: 'intervention',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/intervention/intervention-dialog.html',
                    controller: 'InterventionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xl',
                    windowClass: 'modal-xl',
                    resolve: {
                        entity: function () {
                            return {
                                praxedoId: null,
                                creationDate: null,
                                clientNom: null,
                                clientTel: null,
                                siteTel: null,
                                siteAdress: null,
                                siteCP: null,
                                siteVille: null,
                                equipes: null,
                                duree: null,
                                date: null,
                                activities: null,
                                price: null,
                                id: null,
                                status: null,
                                actProd: null,
                                centralPC: null,
                                agenceNom: null,
                                otFini: null,
                                typeIntervention: null,
                                tailleEquipe: 0,
                                listCommentary: []
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('intervention', null, { reload: 'intervention' });
                }, function() {
                    $state.go('intervention');
                });
            }]
        })
        .state('intervention.edit', {
            parent: 'intervention',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/intervention/intervention-dialog.html',
                    controller: 'InterventionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Intervention', function(Intervention) {
                            return Intervention.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('intervention', null, { reload: 'intervention' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('intervention.commentary', {
            parent: 'intervention',
            url: '/{id}/commentary',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/intervention/intervention-commentary-dialog.html',
                    controller: 'InterventionCommentaryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Intervention', function(Intervention) {
                            return Intervention.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('intervention', null, { reload: 'intervention' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('intervention.delete', {
            parent: 'intervention',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/intervention/intervention-delete-dialog.html',
                    controller: 'InterventionDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Intervention', function(Intervention) {
                            return Intervention.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('intervention', null, { reload: 'intervention' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('intervention-detail.regul', {
            parent: 'intervention-detail',
            url: '/regul/{activityCode}',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/intervention/intervention-regul-dialog.html',
                    controller: 'InterventionRegulDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['Intervention', function(Intervention) {
                            return Intervention.get({id : $stateParams.id}).$promise;
                        }],
                        activityCode: function() {
                            return $stateParams.activityCode;
                        }
                    }
                }).result.then(function() {
                    $state.go('intervention-detail', null, { reload: 'intervention' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }

})();
