(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('ChargeTechnicienDeleteController',ChargeTechnicienDeleteController);

    ChargeTechnicienDeleteController.$inject = ['$uibModalInstance', 'entity', 'ChargeTechnicien'];

    function ChargeTechnicienDeleteController($uibModalInstance, entity, ChargeTechnicien) {
        var vm = this;

        vm.chargeTechnicien = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ChargeTechnicien.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
