(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('ImportConstructelController', ImportConstructelController);

    ImportConstructelController.$inject = ['$state', 'DataUtils', 'ImportConstructel', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function ImportConstructelController($state, DataUtils, ImportConstructel, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.process = process;
        vm.paid = paid;
        vm.search = search;
        vm.resetProcess = resetProcess;

        vm.filterStatus = pagingParams.status;
        vm.filterSearch = pagingParams.search;
        
        loadAll();

        function loadAll () {
            ImportConstructel.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                status: vm.filterStatus,
                search: vm.filterSearch,
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.importConstructels = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                status: vm.filterStatus,
                search: vm.filterSearch
            });
        }
        
        function process(id){
        	ImportConstructel.process({id:id},function(s){
        		loadAll();
        	}, function(error) {
                AlertService.error(error.data.message);
            });
        }

        function paid(id){
        	ImportConstructel.paid({id:id},function(s){
        		loadAll();
        	}, function(error) {
                AlertService.error(error.data.message);
            });
        }
        

        function resetProcess(){
        	ImportConstructel.resetProcess({ }, function(s){
        		transition();
        	}, function(error) {
                AlertService.error(error.data.message);
            });
        }
        
        function search(){
        	vm.transition();
        }
    }
})();
