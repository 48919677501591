(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('PaieDetailController', PaieDetailController);

    PaieDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Paie'];

    function PaieDetailController($scope, $rootScope, $stateParams, previousState, paieResponseDTO, Paie) {
        var vm = this;

        vm.paie = paieResponseDTO.paie;
        vm.conges = paieResponseDTO.conges;
        vm.astreintes = paieResponseDTO.astreintes;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('tmgApp:paieUpdate', function(event, result) {
            vm.paie = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
