(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('ChargeTechnicienController', ChargeTechnicienController);

    ChargeTechnicienController.$inject = ['$state', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'ChargeTechnicien', 'Technicien'];

    function ChargeTechnicienController($state, ParseLinks, AlertService, pagingParams, paginationConstants, ChargeTechnicien, Technicien) {
        var vm = this;

        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.filterTechnicien = pagingParams.technicien;
        vm.filterDate = pagingParams.date;
        vm.filterDateFrom = pagingParams.dateFrom;
        vm.filterDateTo = pagingParams.dateTo;

        vm.selectedYear = pagingParams.year;
        vm.selectedMonth = pagingParams.month;

        vm.search = search;
        vm.transition = transition;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.filterDate = false;
        vm.datePickerOpenStatus.filterMonth = false
        vm.yearDispo = [];
        vm.months = [
            { value: 1, name: 'Janvier' },
            { value: 2, name: 'Février' },
            { value: 3, name: 'Mars' },
            { value: 4, name: 'Avril' },
            { value: 5, name: 'Mai' },
            { value: 6, name: 'Juin' },
            { value: 7, name: 'Juillet' },
            { value: 8, name: 'Août' },
            { value: 9, name: 'Septembre' },
            { value: 10, name: 'Octobre' },
            { value: 11, name: 'Novembre' },
            { value: 12, name: 'Décembre' }
        ];
        vm.filterByPeriod = filterByPeriod;

        loadAll();
        loadTechniciens();
        createArrayOfYear();

        function loadAll () {
            ChargeTechnicien.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                technicien: vm.filterTechnicien,
                date: vm.filterDate,
                dateFrom: vm.filterDateFrom,
                dateTo: vm.filterDateTo,
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),];
                result.push('id');
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.chargeTechnicien = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadTechniciens(){
            Technicien.technicienEnabledHistory({ }, function onSuccess(data, headers) {
                vm.techniciens = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function filterByPeriod() {
            if (vm.selectedMonth) {
                //filtrer par mois
                var selectedMonth = parseInt(vm.selectedMonth);
                var year = vm.selectedYear ? vm.selectedYear : new Date().getFullYear();

                var startDate = new Date(year, selectedMonth - 1, 1);
                var endDate = new Date(year, selectedMonth, 0);

                vm.filterDateFrom = formatDate(startDate);
                vm.filterDateTo = formatDate(endDate);
            } else {
                if (vm.selectedYear){
                    //filtrer par année
                    vm.filterDateFrom = formatDate(new Date(vm.selectedYear, 0, 1));
                    vm.filterDateTo = formatDate(new Date(vm.selectedYear, 11, 31));
                }
                else {
                    vm.filterDateFrom = null;
                    vm.filterDateTo = null;
                }
            }
        }

        function formatDate(date) {
            return new Date(date).toISOString().split('T')[0];
        }

        function createArrayOfYear() {
            var currentDate = new Date();
            for (var i = 2020; i <= currentDate.getFullYear()+1 ; i++) {
                vm.yearDispo.push(i);
            }
        }

        function search(){
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, Object.assign(createChargeTechnicienFilter(), {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            }));
        }
        function createChargeTechnicienFilter(){
            return {
                technicien: vm.filterTechnicien,
                date: vm.filterDate,
                month: vm.selectedMonth,
                year: vm.selectedYear,
                dateFrom: vm.filterDateFrom,
                dateTo: vm.filterDateTo,
            }
        }

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
