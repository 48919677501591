(function() {
    'use strict';
    angular
        .module('tmgApp')
        .factory('Calendar', Calendar);

    Calendar.$inject = ['$resource'];

    function Calendar ($resource) {
        var resourceUrl =  'api/calendar';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                params: {
                    calendarFilter: '@calendarFilter',
                    start: '@start'
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
        });
    }
})();
