(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('ResultatTechnicienController', ResultatTechnicienController);

    ResultatTechnicienController.$inject = ['$state', 'ParseLinks', 'AlertService', 'pagingParams', '$filter', 'paginationConstants', 'ResultatTechnicien', 'Technicien', 'DateUtils', 'ChargeTechnicien', 'Config'];

    function ResultatTechnicienController($state, ParseLinks, AlertService, pagingParams, $filter, paginationConstants, ResultatTechnicien, Technicien, DateUtils, ChargeTechnicien, Config) {
        var vm = this;

        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.filterTechnicien = pagingParams.technicien;
        vm.filterType = pagingParams.type;
        vm.filterDateTo = new Date(pagingParams.dateTo);
        vm.filterDateFrom = new Date(pagingParams.dateFrom);

        if (!pagingParams.dateFrom && !pagingParams.dateTo) {
            getAllDates()
        }

        vm.getTotal = getTotal;
        vm.getCATotal = getCATotal;
        vm.getChargeTotal = getChargeTotal;
        vm.search = search;
        vm.addChargePreviousMonth = addChargePreviousMonth;
        vm.transition = transition;
        vm.reportChargeBtnDisable = false;

        loadAll();
        loadTechniciens();
        getLastMonthChargeReportDate();

        function loadAll() {
            vm.filterType = vm.filterType === "" ? null : vm.filterType;
            ResultatTechnicien.query({
                dateFrom: vm.filterDateFrom.toISOString().split('T')[0],
                dateTo: vm.filterDateTo.toISOString().split('T')[0],
                technicien: vm.filterTechnicien,
                type: vm.filterType
            },onSuccess, onError);
        }

        function addChargePreviousMonth() {
            ChargeTechnicien.addChargePreviousMonth({}, function onSuccess(data, headers) {
                getLastMonthChargeReportDate();
                loadAll();
                AlertService.success("Charges ajoutées avec succès");
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function getLastMonthChargeReportDate() {
            Config.getByName({name: 'date_dernier_mois_creation_charge'}, function onSuccess(data, headers) {
                var dateString = data.value;
                var parts = dateString.split("-");
                var date = new Date(parts[0], parts[1] - 1, parts[2]);
                vm.lastMonthChargeReportDate = date;
                adaptReportChargeBtn();
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function loadTechniciens(){
            Technicien.technicienEnabledHistory ({ }, function onSuccess(data, headers) {
                vm.techniciens = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function onSuccess(data, headers) {
            vm.resultatTechniciens = data;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }

        function getTotal(){
            var total=0;
            if(vm.resultatTechniciens){
                vm.resultatTechniciens.forEach(function(resultat) {
                    if(resultat.price){
                        total+=resultat.price;
                    }
                });
            }
            return numberWithSpace(total.toFixed(2));
        }

        function adaptReportChargeBtn() {
            var month = DateUtils.getFirstDayOfMonth(new Date());
            var firstDayOfPeriod= DateUtils.getFirstDayOfPeriod(month);
            var lastDayOfPeriod = DateUtils.getLastDayOfPeriod(month);

            if (firstDayOfPeriod <= vm.lastMonthChargeReportDate && vm.lastMonthChargeReportDate <= lastDayOfPeriod) {
                vm.reportChargeBtnDisable = true;
            } else {
                vm.reportChargeBtnDisable = false;
            }
        }

        function getCATotal(){
            var ca=0;
            if(vm.resultatTechniciens){
                vm.resultatTechniciens.forEach(function(resultat) {
                    if(resultat.price && resultat.price > 0){
                        ca+=resultat.price;
                    }
                });
            }
            return numberWithSpace(ca.toFixed(2));
        }

        function getChargeTotal(){
            var charge=0;
            if(vm.resultatTechniciens){
                vm.resultatTechniciens.forEach(function(resultat) {
                    if(resultat.price && resultat.price < 0){
                        charge+=resultat.price;
                    }
                });
            }
            return numberWithSpace(charge.toFixed(2));
        }

        function numberWithSpace(x) {
            if(x){
                var parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                return parts.join(".");
            }else{
                return null;
            }
        }

        function getAllDates() {
            vm.month = DateUtils.getFirstDayOfMonth(new Date());
            vm.filterDateFrom = DateUtils.getFirstDayOfPeriod(vm.month);
            vm.filterDateTo = DateUtils.getLastDayOfPeriod(vm.month);
        }

        vm.previousMonth=function(){
            vm.month = DateUtils.getFirstDayOfMonth(new Date(vm.filterDateTo.getFullYear(), vm.filterDateTo.getMonth() - 1, 1));
            vm.filterDateFrom = DateUtils.getFirstDayOfPeriod(vm.month);
            vm.filterDateTo = DateUtils.getLastDayOfPeriod(vm.month);
            vm.filterDateFrom = $filter('date')(vm.filterDateFrom,'yyyy-MM-dd');
            vm.filterDateTo = $filter('date')(vm.filterDateTo,'yyyy-MM-dd');
            transition();
        }

        vm.nextMonth=function(){
            vm.month = DateUtils.getFirstDayOfMonth(new Date(vm.filterDateTo.getFullYear(), vm.filterDateTo.getMonth() + 1, 1));
            vm.filterDateFrom = DateUtils.getFirstDayOfPeriod(vm.month);
            vm.filterDateTo = DateUtils.getLastDayOfPeriod(vm.month);
            vm.filterDateFrom = $filter('date')(vm.filterDateFrom,'yyyy-MM-dd');
            vm.filterDateTo = $filter('date')(vm.filterDateTo,'yyyy-MM-dd');
            transition();
        }

        vm.previousDay=function (){
            var date = new Date(vm.filterDateFrom);
            date.setDate(date.getDate() - 1);
            var tmpDateTo = new Date(vm.filterDateTo);
            tmpDateTo.setDate(tmpDateTo.getDate() - 1);
            vm.filterDateFrom = $filter('date')(date,'yyyy-MM-dd');
            vm.filterDateTo = $filter('date')(tmpDateTo,'yyyy-MM-dd');
            transition();
        }

        vm.nextDay=function (){
            var date = new Date(vm.filterDateFrom);
            date.setDate(date.getDate() + 1);
            var tmpDate = new Date(vm.filterDateTo);
            tmpDate.setDate(tmpDate.getDate() + 1);
            vm.filterDateFrom = $filter('date')(date,'yyyy-MM-dd');
            vm.filterDateTo = $filter('date')(tmpDate,'yyyy-MM-dd');
            transition();
        }

        function search(){
            transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                dateFrom: vm.filterDateFrom,
                dateTo: vm.filterDateTo,
                technicien: vm.filterTechnicien,
                type: vm.filterType,
            });
        }

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
