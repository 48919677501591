(function() {
    'use strict';
    angular
        .module('tmgApp')
        .controller('BonusObjWeekController', BonusObjWeekController);

    BonusObjWeekController.$inject = ['$state','$uibModal','AlertService','Principal', 'pagingParams','BonusObjWeek','BonusObjWeek'];

    function BonusObjWeekController ($state,$uibModal,AlertService, Principal, pagingParams, BonusObjWeek) {
        //Properties
        var vm = this;
        vm.updateWeek = updateWeek;
        vm.dayOfWeek = ['Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'];
        vm.selectedWeek = new Date(pagingParams.searchDate);
        vm.existingMonth = moment.months();
        vm.listTechniciens = [];

        //Methods
        vm.updateWeek = updateWeek;
        vm.numberOfWeek = numberOfWeek;
        vm.titleCaseWord = titleCaseWord;
        vm.getDayFromDate = getDayFromDate;
        vm.calculatePoints = calculatePoints;
        vm.calculatePalier= calculatePalier;
        vm.defineTdColor = defineTdColor;
        loadAllEventCalendar();

        function updateWeek(arg) {
            if (arg === "prev")
                vm.selectedWeek = previousWeek(vm.selectedWeek);
            if (arg === "next")
                vm.selectedWeek = nextWeek(vm.selectedWeek);
            if (arg === "actual")
                vm.selectedWeek = goToCurrent(vm.selectedWeek);
            pagingParams.searchDate = moment(vm.selectedWeek).format("YYYY-MM-DD");
            $state.transitionTo($state.$current, {
                searchDate: pagingParams.searchDate
            });
        }

        function loadAllEventCalendar() {
            BonusObjWeek.query({
                searchDate: pagingParams.searchDate
            },onSuccess, onError);
        }

        function onSuccess(data) {
            vm.listTechniciens = Object.keys(data);
            vm.listTechniciens.splice(vm.listTechniciens.length - 2,2);
            vm.ApiResponseTab = Object.values(data);
            vm.ApiResponseTab.splice(vm.ApiResponseTab.length - 2,2);
            console.log(vm.ApiResponseTab)
        }

        function defineTdColor(congeType){
            var classTd = "";
            if(congeType != null ) {
                switch (congeType) {
                    case "AT" : classTd+= " tdPink"; break;
                    case "CHP" : classTd+= " tdGrey"; break;
                    case "CP" : classTd+= " tdBlue"; break;
                    case "JR": classTd += " tdGreen"; break;
                    case "JD": classTd += " tdOrange"; break;
                    case "GD": classTd += " tdPurple"; break;
                }
            }
            return classTd;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function numberOfWeek() {
            return moment(new Date(vm.selectedWeek)).weeks();
        }

        function nextWeek(date) {
            return new Date(date.setDate(date.getDate()+7));
        }

        function previousWeek(date) {
            return new Date(date.setDate(date.getDate()-7));
        }

        function goToCurrent() {
            vm.selectedWeek = new Date();
            return vm.selectedWeek;
        }

        function titleCaseWord(word) {
            if (!word) return word;
            return word[0].toUpperCase() + word.substr(1).toLowerCase();
        }

        function getDayFromDate(day) {
            //find the monday of the week
            return moment(new Date(vm.selectedWeek)).isoWeekday(day).format('DD');
        }

        function calculatePoints(technicienInfo) {
            var points = 0;
            for (var i = 0; i < technicienInfo.days; i++) {
                points += technicienInfo.days[i].pointObj;
            }
            points+= technicienInfo.pointValorisation;
            return points;
        }

        function calculatePalier(number) {
            if (number >= 5 && number < 15) {
                return 1;
            } else if (number >= 15 && number < 25) {
                return 2;
            } else if (number >= 25 && number < 35) {
                return 3;
            }else if (number >= 35 && number < 45) {
                return 4;
            }
        }
    }

})();
