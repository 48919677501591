(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('ExportPraxedoNewDialogController', ExportPraxedoNewDialogController);

    ExportPraxedoNewDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ExportPraxedo','$http', 'DataUtils'];

    function ExportPraxedoNewDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ExportPraxedo,$http, DataUtils) {
        var vm = this;

        vm.exportPraxedo = entity;
        vm.clear = clear;
        vm.save = save;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.exportPraxedo.files.forEach(function(file){
                file.typeImport = vm.exportPraxedo.typeImport;
            })
            ExportPraxedo.saveNewFile(vm.exportPraxedo.files, onSaveSuccess, onSaveError);
        }

        vm.setFiles = function ($files, exportFiles) {
            if ($files && $files.length) {
                $files.forEach(function(file) {
                    DataUtils.toBase64(file, function(base64Data) {
                        $scope.$apply(function() {
                            var exportFile = {
                                fileName: file.name,
                                file: base64Data,
                                fileContentType: file.type
                            };
                            exportFiles.files.push(exportFile);
                        });
                    });
                });
            }
            console.log(exportFiles);
        };

        vm.removeFile = function (file) {
            var index = vm.exportPraxedo.files.indexOf(file);
            if (index !== -1) {
                vm.exportPraxedo.files.splice(index, 1);
            }
        };

        function onSaveSuccess (result) {
            $scope.$emit('tmgApp:exportPraxedoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
