(function() {
    'use strict';
    angular
        .module('tmgApp')
        .factory('Technicien', Technicien);

    Technicien.$inject = ['$resource', 'DateUtils'];

    function Technicien ($resource, DateUtils) {
        var resourceUrl =  'api/techniciens/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'technicienEnabled': {
                method: 'GET',
                isArray: true,
                url: 'api/techniciens/enabled'
            },
            'technicienEnabledHistory': {
                method: 'GET',
                isArray: true,
                url: 'api/techniciens/history'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
                'verifyAliase' : {
                url: 'api/interventions/verifyAliases/:id',
                method : 'GET',
                hasBody: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAvailableForPlanning': {
                method: 'GET',
                url: 'api/availableTechniciensForPlanning',
                params: {
                    date: '@date',
                },
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAvailableTechByDatesForPlanning': {
                method: 'GET',
                url: 'api/availableTechByDatesForPlanning',
                params: {
                    dateStart: '@dateStart',
                    dateEnd: '@dateEnd'
                },
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        for (var i = 0; i < data.length; i++) {
                            data[i].date = DateUtils.convertLocalDateFromServer(data[i].date);
                        }
                    }
                    return data;
                }
            }
        });
    }
})();
