(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('AstreinteController', AstreinteController);

    AstreinteController.$inject = ['$state', 'Astreinte', 'Technicien', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function AstreinteController($state, Astreinte, Technicien, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.yearDispo = [];
        vm.filterTechnicien = pagingParams.technicien;
        vm.filterYear = pagingParams.year;
        vm.filterMonth = pagingParams.month;


        vm.autoDetect = autoDetect;
        vm.parseNumberToMonth = parseNumberToMonth;
        vm.search=search;

        loadAll();
        loadTechniciens();
        createArrayOfYear();

        function loadAll () {
            Astreinte.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                technicien: vm.filterTechnicien,
                year: vm.filterYear,
                month: vm.filterMonth
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.astreintes = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                technicien: vm.filterTechnicien,
                year: vm.filterYear,
                month: vm.filterMonth
            });
        }

        function loadTechniciens(){
            Technicien.technicienEnabledHistory({ }, function onSuccess(data, headers) {
                vm.techniciens = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function search(){
            transition();
        }

        function autoDetect(){
        	Astreinte.autoDetect({},function(s){
        		loadAll();
        	}, function(error) {
                AlertService.error(error.data.message);
            });
        }

        function createArrayOfYear() {
            var currentDate = new Date();
            for (var i = 2020; i <= currentDate.getFullYear()+1 ; i++) {
                vm.yearDispo.push(i);
            }
        }

        function parseNumberToMonth(month) {
            var today = Date.now();
            var date = moment(today);
            date.set('Month',month);
            return date.format("MMMM");
        }
    }
})();
