(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('AstreinteDialogController', AstreinteDialogController);

    AstreinteDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$uibModal', 'entity', 'Astreinte', 'Technicien'];

    function AstreinteDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $uibModal, entity, Astreinte, Technicien) {
        $(document).ready(function () {
            $('#field_technicien').select2();
            $('#field_technicien').on('select2:select', function (e) {
                vm.astreinte.technicien = $(this).val();
            });
        });
        var vm = this;
        vm.astreinte = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.deleteAstreinte = deleteAstreinte;

        loadTechniciens();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.astreinte.id !== null) {
                Astreinte.update(vm.astreinte, onSaveSuccess, onSaveError);
            } else {
                Astreinte.save(vm.astreinte, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('tmgApp:astreinteUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function loadTechniciens(){
        	Technicien.technicienEnabled({ }, function onSuccess(data, headers) {
                vm.techniciens = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function deleteAstreinte(id) {
            $uibModal.open({
                templateUrl: 'app/entities/astreinte/astreinte-delete-dialog.html',
                controller: 'AstreinteDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['Astreinte', function(Astreinte) {
                        return Astreinte.get({id : id}).$promise;
                    }]
                }
            }).result.then(function () {
                $uibModalInstance.close(true);
                $scope.go($state.current, {}, {reload: true});
            });
        }
    }
})();
