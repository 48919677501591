(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('ValorisationDeleteController',ValorisationDeleteController);

    ValorisationDeleteController.$inject = ['$uibModalInstance', 'entity', 'Valorisation'];

    function ValorisationDeleteController($uibModalInstance, entity, Valorisation) {
        var vm = this;

        vm.valorisation = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Valorisation.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
