(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('SfrFileDetailController', SfrFileDetailController);

    SfrFileDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'SfrFile'];

    function SfrFileDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, SfrFile) {
        var vm = this;

        vm.sfrFile = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('tmgApp:sfrFileUpdate', function(event, result) {
            vm.sfrFile = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
