(function() {
    'use strict';
    angular
        .module('tmgApp')
        .factory('ResultatTechnicien', ResultatTechnicien);

    ResultatTechnicien.$inject = ['$resource', 'DateUtils'];

    function ResultatTechnicien ($resource, DateUtils) {
        var resourceUrl =  'api/resultat-technicien/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
        });

    }
})();
