(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('AstreintePlanifieDeleteController',AstreintePlanifieDeleteController);

    AstreintePlanifieDeleteController.$inject = ['$uibModalInstance', 'entity', 'AstreintePlanifie'];

    function AstreintePlanifieDeleteController($uibModalInstance, entity, AstreintePlanifie) {
        var vm = this;

        vm.astreintePlanifie = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id,includeRepeat) {
            AstreintePlanifie.delete({id: id,includeRepeat: includeRepeat},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
