(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'User', 'Technicien', 'JhiLanguageService'];

    function UserManagementDialogController ($stateParams, $uibModalInstance, entity, User, Technicien, JhiLanguageService) {
        $(document).ready(function () {
            $('#filterTechnicien').select2();
            $('#filterTechnicien').on('select2:select', function (e) {
                vm.user.technicien={};
				vm.user.technicien.id = $('#filterTechnicien :selected').val();
                vm.user.technicien.displayname = $('#filterTechnicien :selected').text();
            });
        });
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_TECHNICIEN', 'ROLE_AFFICHAGE'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.techniciens = [];
        vm.user = entity;

        loadTechniciens();

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function loadTechniciens(){
            Technicien.technicienEnabled({ }, function onSuccess(data, headers) {
                vm.techniciens = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();
