(function() {
    'use strict';
    angular
        .module('tmgApp')
        .factory('TechnicienHomeStat', TechnicienHomeStat);

    TechnicienHomeStat.$inject = ['$resource', 'DateUtils'];

    function TechnicienHomeStat ($resource, DateUtils) {
        var resourceUrl =  'api/technicien-home-stat';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
        });

    }
})();
