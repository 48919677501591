(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('CongesDetailController', CongesDetailController);

    CongesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Conges'];

    function CongesDetailController($scope, $rootScope, $stateParams, previousState, entity, Conges) {
        var vm = this;

        vm.conges = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('tmgApp:congesUpdate', function(event, result) {
            vm.conges = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
