(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('TechnicienDetailController', TechnicienDetailController);

    TechnicienDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Technicien'];

    function TechnicienDetailController($scope, $rootScope, $stateParams, previousState, entity, Technicien) {
        var vm = this;

        vm.technicien = entity;
        vm.previousState = previousState.name;
        vm.verifyAliase = verifyAliase;

        var unsubscribe = $rootScope.$on('tmgApp:technicienUpdate', function(event, result) {
            vm.technicien = result;
        });
        $scope.$on('$destroy', unsubscribe);

         function verifyAliase() {
             Technicien.verifyAliase({id: entity.id}, function (s) {
                 console.log(s);
             }, function (error) {
                 AlertService.error(error.data.message);
             });
         }
    }
})();
