(function() {
    'use strict';

    angular
        .module('tmgApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('charge-technicien', {
                url: '/charge-technicien?technicien&date&year&month&page&sort',
                parent: 'gestion',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Charge technicien'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/charge-technicien/charge-technicien.html',
                        controller: 'ChargeTechnicienController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'date,desc',
                        squash: true
                    },
                    technicien: null,
                    date: null,
                    dateFrom: null,
                    dateTo: null,
                    month: null,
                    year: null,

                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            technicien: $stateParams.technicien,
                            date: $stateParams.date,
                            year: $stateParams.year,
                            month: $stateParams.month,
                            dateFrom: $stateParams.dateFrom,
                            dateTo: $stateParams.dateTo,
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('charge-technicien.new', {
                parent: 'charge-technicien',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/charge-technicien/charge-technicien-dialog.html',
                        controller: 'ChargeTechnicienDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null,
                                    techniciens: null,
                                    intervention: null,
                                    date: null,
                                    description: null,
                                    type: null,
                                    charge: null,
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('charge-technicien', null, { reload: 'charge-technicien' });
                    }, function() {
                        $state.go('charge-technicien');
                    });
                }]
            })
            .state('charge-technicien.edit', {
                parent: 'charge-technicien',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/charge-technicien/charge-technicien-dialog.html',
                        controller: 'ChargeTechnicienDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ChargeTechnicien', function(ChargeTechnicien) {
                                return ChargeTechnicien.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('charge-technicien', null, { reload: 'charge-technicien' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('charge-technicien-detail', {
                parent: 'charge-technicien',
                url: '/charge-technicien/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    // pageTitle: 'tmgApp.groupePlanning.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/charge-technicien/charge-technicien-detail.html',
                        controller: 'ChargeTechnicienDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ChargeTechnicien', function($stateParams, ChargeTechnicien) {
                        return ChargeTechnicien.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'charge-technicien',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('charge-technicien.delete', {
                parent: 'charge-technicien',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/charge-technicien/charge-technicien-delete-dialog.html',
                        controller: 'ChargeTechnicienDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['ChargeTechnicien', function(ChargeTechnicien) {
                                return ChargeTechnicien.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('charge-technicien', null, { reload: 'charge-technicien' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }
})();
