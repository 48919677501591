(function() {
    'use strict';

    angular
        .module('tmgApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('equipe', {
                parent: 'gestion',
                url: '/equipes?dateCalendar&page&sort&year&month&week&chef',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'tmgApp.equipe.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/equipe/equipe.html',
                        controller: 'EquipeController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    dateCalendar:null,
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    year:null,
                    month:null,
                    chef: null,
                    week: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            dateCalendar: $stateParams.dateCalendar,
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            year: $stateParams.year,
                            month: $stateParams.month,
                            chef: $stateParams.chef,
                            week: $stateParams.week,
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('equipe');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('equipe.copy', {
                parent: 'equipe',
                url: '/copy/{date}',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams','$state', '$uibModal', function($stateParams, $state, $uibModal)  {
                    $uibModal.open({
                        templateUrl: 'app/entities/equipe/equipe-copy-modal.html',
                        controller: 'EquipeCopyModalController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {}
                    }).result.then(function() {
                        $state.go('equipe', null, { reload: 'equipe' });
                    }, function() {
                        $state.go('equipe', null, { reload: true });
                    });
                }]
            })
            .state('equipe.edit', {
                parent: 'equipe',
                url: '/{date}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/equipe/equipe-dialog.html',
                        controller: 'EquipeDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'l',
                        windowClass: 'modal-l',
                    }).result.then(function() {
                        $state.go('equipe', null, { reload: 'equipe' });
                    }, function() {
                        $state.go('equipe', null, { reload: true });
                    });
                }]
            })
    }

})();
