(function() {
    'use strict';

    angular
        .module('tmgApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('bonusObjWeek', {
            parent: 'app',
            url: '/bonusObjWeek?searchDate',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/bonusObjWeek/bonusObjWeek.html',
                    controller: 'BonusObjWeekController',
                    controllerAs: 'vm'
                }
            },
            params: {
                searchDate: moment().format("YYYY-MM-DD")
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                pagingParams: ['$stateParams', function ($stateParams) {
                    return {
                        searchDate: $stateParams.searchDate
                    }
                }]
            }
        });
    }
})();
