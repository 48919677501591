(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('InterventionDetailController', InterventionDetailController);

    InterventionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Intervention', 'AlertService','$state','Technicien', 'Principal','Point', 'ChargeTechnicien', 'DateUtils'];

    function InterventionDetailController($scope, $rootScope, $stateParams, previousState, entity, Intervention, AlertService, $state,Technicien, Principal, Point, ChargeTechnicien, DateUtils) {
        $(document).ready(function () {
            $('#field_technicien').select2();
            $('#field_technicien').on('select2:select', function (e) {
                vm.newTech = $('#field_technicien :selected').val();
            });
        });
        var vm = this;

        vm.datePickerOpenStatus = {};
        vm.bonus = {
			point:null,
			qty:1
		};
        vm.intervention = entity;
        vm.previousState = previousState.name;
        vm.manualClose = manualClose;
        vm.removeTech = removeTech;
        vm.checkIfCanDelete = checkIfCanDelete;
        vm.removeCommentary = removeCommentary;
        vm.addBonDeCommande = addBonDeCommande;
        vm.removeBonDeCommande = removeBonDeCommande;
        vm.newTech= null;
        vm.newCharge = null;
        vm.newBonDeCommande = null;
        vm.pointsTMG = [];

        vm.addTech = addTech;
        vm.addBonus = addBonus;
        vm.addCharge = addCharge;
        vm.removeCharge = removeCharge;
        vm.removeBonus = removeBonus;
        vm.manualClose = manualClose;
        vm.removeTech = removeTech;
        vm.changeFactureForActivity = changeFactureForActivity;
        vm.changeCaTechnicienForActivity = changeCaTechnicienForActivity;
        vm.isAdmin = false;
		vm.editDate = false;
        vm.openCalendar = openCalendar;
		vm.saveNewDate = saveNewDate;
        vm.isInMonthRange = isInMonthRange;
        vm.isDateFuture = isDateFuture;


        Principal.identity().then(function(account) {
            if(account.authorities.indexOf("ROLE_ADMIN") === 1) {
                vm.isAdmin = true;
            }
        });

        var unsubscribe = $rootScope.$on('tmgApp:interventionUpdate', function(event, result) {
            vm.intervention = result;
        });
        $scope.$on('$destroy', unsubscribe);


        getAllDates();
        loadTechniciens();
        loadPoints();
        loadCharges();

        function isInMonthRange(){
            return !(vm.intervention.date < vm.dateStart || vm.intervention.date > vm.dateEnd);
        }

        function removeTech(technicien) {
            Intervention.removeTech({id: vm.intervention.id,tech: technicien},onSuccess, onError);
        }

        function removeCommentary(commentary) {
            Intervention.removeCommentary({id: vm.intervention.id,commentary: commentary},onSuccess, onError);
        }

        function addBonDeCommande() {
            Intervention.addBonDeCommande({id: vm.intervention.id, bonDeCommande: vm.newBonDeCommande}, onSuccess, onError);
        }

        function removeBonDeCommande(bonDeCommande){
            Intervention.removeBonDeCommande({id: vm.intervention.id, bonDeCommande: bonDeCommande},onSuccess, onError);
        }

        function changeFactureForActivity(activity){
            if(!vm.isSaving){
                vm.isSaving = true
                Intervention.changeActivityFacture({id: vm.intervention.id, activityCode: activity.code},onSuccess, onError);
            }
        }

        function changeCaTechnicienForActivity(activity){
            if(!vm.isSaving){
                vm.isSaving = true
                Intervention.changeActivityCaTechnicien({id: vm.intervention.id, activityCode: activity.code},onSuccess, onError);
            }
        }

        function onSuccess() {
            vm.isSaving = false
            $state.go($state.current, {}, {reload: true});
        }
        function onError(error) {
            vm.isSaving = false
            AlertService.error(error.data.message);
            $state.go($state.current, {}, {reload: true});
        }

        function manualClose(id){
        	Intervention.manualClose({id:id},function(s){
        		vm.intervention.typeIntervention='MANUAL_CLOSE';
        	}, function(error) {
                AlertService.error(error.data.message);
            });
        }

        function addTech() {
            if (vm.newTech !== undefined && vm.newTech !== null) {
                Intervention.addTech({id: vm.intervention.id,tech: vm.newTech},onSuccess, onError);
            }
        }

        function addBonus() {
            Intervention.addActivity({interventionId: vm.intervention.id, activityCode: vm.bonus.point, qty: vm.bonus.qty},onSuccess, onError);
        }

        function removeBonus(bonus) {
            Intervention.removeActivity({id: vm.intervention.id,code: bonus.code},onSuccess, onError);
        }

        function addCharge() {
            vm.newCharge.intervention = vm.intervention.id;
            vm.newCharge.techniciens = vm.intervention.equipes;
            var dateInter = new Date(vm.intervention.date);
            vm.newCharge.date = new Date(dateInter.getFullYear(), dateInter.getMonth() + 1, 1);
            ChargeTechnicien.save(vm.newCharge, onSuccess, onError);
        }

        function removeCharge(charge) {
            ChargeTechnicien.delete({id: charge.id}, onSuccess, onError);
        }

        function checkIfCanDelete() {
            return vm.intervention.equipes.length > 1;
        }

        function getAllDates() {
            vm.month = DateUtils.getFirstDayOfMonth(new Date());
            vm.dateStart = DateUtils.getFirstDayOfPeriod(vm.month);
            vm.dateEnd = DateUtils.getLastDayOfPeriod(vm.month);
        }

        function isDateFuture(date) {
            var today = new Date();
            today.setHours(0, 0, 0, 0);
            var inputDate = new Date(date);
            inputDate.setHours(0, 0, 0, 0);
            return inputDate > today;
        }


        function loadTechniciens(){
            Technicien.technicienEnabledHistory({ }, function onSuccess(data, headers) {
                vm.techniciens = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function loadCharges() {
            ChargeTechnicien.getByIntervention({ interventionId: vm.intervention.id }, function onSuccess(data, headers) {
                vm.chargesTechnicien = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function loadPoints(){
            Point.categoryTMG({ }, function onSuccess(data, headers) {
                vm.pointsTMG = data;
            });
        }

        vm.datePickerOpenStatus.creationDate = false;
        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

		function saveNewDate() {

            var newDate = moment(vm.intervention.date).format('YYYY-MM-DD')
			Intervention.changeDate({id: vm.intervention.id,date: newDate},onSuccess, onError);
		}

    }
})();
