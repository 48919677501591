(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('OutilDialogController', OutilDialogController);

    OutilDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Outil', 'Config', 'Technicien'];

    function OutilDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Outil, Config, Technicien) {
        var vm = this;

        vm.outil = entity;
        vm.clear = clear;
        vm.save = save;
        vm.isSaving = false;
        vm.monthlyToDailyPrice = monthlyToDailyPrice;
        vm.dailyToMonthlyPrice = dailyToMonthlyPrice;
        vm.loadTechniciens = loadTechniciens;

        getConfig();
        loadTechniciens();

        vm.newOutil = null;

        function save() {
            vm.isSaving = true;
            vm.outil.technicien = vm.outil.technicien === "" ? null : vm.outil.technicien;
            if (vm.outil.id !== null){
                Outil.update(vm.outil, onSaveSuccess, onSaveError);
            } else {
                Outil.save(vm.outil, onSaveSuccess, onSaveError);
            }
        }

        function loadTechniciens(){
            Technicien.technicienEnabled({ }, function onSuccess(data, headers) {
                vm.techniciens = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function monthlyToDailyPrice() {
            if(vm.outil.monthlyPrice){
                vm.outil.dailyPrice = parseFloat((vm.outil.monthlyPrice * vm.configRatioMensuelJournalier).toFixed(2));
            }
        }

        function dailyToMonthlyPrice() {
            if(vm.outil.dailyPrice){
                vm.outil.monthlyPrice = parseFloat((vm.outil.dailyPrice / vm.configRatioMensuelJournalier).toFixed(2));
            }
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getConfig() {
            Config.getByName({name: "ratio_mensuel_journalier"}, onSuccess, onError);
            function onSuccess(data) {
                vm.configRatioMensuelJournalier = +data.value;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
