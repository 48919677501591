(function() {
    'use strict';

    angular
        .module('tmgApp')
        .factory('DateUtils', DateUtils);

    DateUtils.$inject = ['$filter'];

    function DateUtils($filter) {

        var service = {
            convertDateTimeFromServer: convertDateTimeFromServer,
            convertLocalDateFromServer: convertLocalDateFromServer,
            convertLocalDateToServer: convertLocalDateToServer,
            dateformat: dateformat,
            getFirstDayOfMonth: getFirstDayOfMonth,
            getFirstDayOfPeriod: getFirstDayOfPeriod,
            getLastDayOfPeriod: getLastDayOfPeriod
        };

        return service;

        function convertDateTimeFromServer(date) {
            if (date) {
                return new Date(date);
            } else {
                return null;
            }
        }

        function convertLocalDateFromServer(date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer(date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        function dateformat() {
            return 'yyyy-MM-dd';
        }

        function getFirstDayOfMonth(date) {
            var currentMonth = date.getMonth();
            var currentYear = date.getFullYear();

            var firstDayOfCurrentMonth = new Date(currentYear, currentMonth, 1);

            var lastDayOfCurrentMonth = new Date(currentYear, currentMonth + 1, 0);

            var lastSaturdayOfCurrentMonth = new Date(lastDayOfCurrentMonth);
            var dayOfWeek = lastDayOfCurrentMonth.getDay();

            lastSaturdayOfCurrentMonth.setDate(lastDayOfCurrentMonth.getDate() - ((dayOfWeek + 1) % 7));

            // Vérifier si aujourd'hui est après le dernier samedi du mois en cours
            if (date > lastSaturdayOfCurrentMonth) {
                currentMonth += 1;
                if (currentMonth > 11) {
                    currentMonth = 0;
                    currentYear += 1;
                }
            }

            return new Date(currentYear, currentMonth, 1);
        }

        function getFirstDayOfPeriod(firstDayOfMonth) {
            // dimanche précédent la 1ere semaine du mois

            var daysToSubtract = firstDayOfMonth.getDay();
            var firstSundayBeforeMonth = new Date(firstDayOfMonth);
            firstSundayBeforeMonth.setDate(firstDayOfMonth.getDate() - daysToSubtract);
            firstSundayBeforeMonth.setHours(firstSundayBeforeMonth.getHours() + 3);
            return firstSundayBeforeMonth;
        }

        function getLastDayOfPeriod(firstDayOfMonth) {
            //dernier samedi du mois
            var year = firstDayOfMonth.getFullYear();
            var month = firstDayOfMonth.getMonth();
            var lastDayOfMonth = new Date(year, month + 1, 0);

            var dayOfWeek = lastDayOfMonth.getDay();
            var daysToSubtract = dayOfWeek < 6 ? dayOfWeek + 1 : 0;

            var lastSaturdayOfMonth = new Date(lastDayOfMonth);
            lastSaturdayOfMonth.setDate(lastDayOfMonth.getDate() - daysToSubtract);

            lastSaturdayOfMonth.setHours(lastSaturdayOfMonth.getHours() + 3);
            return lastSaturdayOfMonth;
        }
    }

})();
