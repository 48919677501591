(function (){
    'use strict';
    angular
        .module('tmgApp')
        .factory('BonusObjWeek', BonusObjWeek);

    BonusObjWeek.$inject = ['$resource'];

    function BonusObjWeek ($resource) {
        var resourceUrl =  'api/bonusObjWeek';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
        });
    }
})();
