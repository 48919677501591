(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('InterventionRegulDialogController', InterventionRegulDialogController);

    InterventionRegulDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'activityCode', 'Intervention', 'ChargeTechnicien', 'Point'];

    function InterventionRegulDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, activityCode, Intervention, ChargeTechnicien, Point) {
        var vm = this;

        vm.intervention = entity;
        vm.activityCode = activityCode;
        vm.qty = 1;
        vm.newPoint = null;
        vm.clear = clear;
        vm.regulCharge = regulCharge;
        vm.getActivity = getActivity;

        loadPoints();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function getActivity() {
            for (var i = 0; i < vm.intervention.activities.length; i++) {
                if (vm.intervention.activities[i].code === vm.activityCode) {
                    return vm.intervention.activities[i];
                }
            }
        }

        function loadPoints(){
            Point.categoryTMG({ }, function onSuccess(data, headers) {
                vm.pointsTMG = data;
            });
        }

        function regulCharge() {
            vm.isSaving = true

            var regulRequest = {
                interventionId: vm.intervention.id,
                activity: getActivity(),
                qty: vm.qty,
                newActivityCode: vm.newPoint
            }

            ChargeTechnicien.regulChargeForActivity(regulRequest, onSuccess, onError);
        }

        function onSuccess () {
            $uibModalInstance.close();
            vm.isSaving = false;
        }

        function onError (error) {
            AlertService.error(error.data.message);
            vm.isSaving = false;
        }
    }
})();
