(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('ChargeTechnicienDialogController', ChargeTechnicienDialogController);

    ChargeTechnicienDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ChargeTechnicien', 'Technicien'];

    function ChargeTechnicienDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ChargeTechnicien, Technicien) {
        $(document).ready(function () {
            $('#field_technicien').select2();
            $('#field_technicien').on('select2:select', function (e) {
                vm.newTech = $('#field_technicien :selected').val();
            });
            adaptCustomFormError();
        });
        var vm = this;

        vm.chargeTechnicien = entity;
        vm.clear = clear;
        vm.addTech = addTech;
        vm.removeTech = removeTech;
        vm.save = save;
        vm.isSaving = false;

        vm.customFormError = true
        vm.newTech = null;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.creationDate = false;
        vm.datePickerOpenStatus.date = false;

        loadTechniciens();

        function save() {
            var date = new Date(vm.chargeTechnicien.date);
            date.setHours(date.getHours() + 2);
            vm.chargeTechnicien.date = date.toISOString();
            vm.isSaving = true;
            if (vm.chargeTechnicien.id !== null){
                ChargeTechnicien.update(vm.chargeTechnicien, onSaveSuccess, onSaveError);
            } else {
                ChargeTechnicien.save(vm.chargeTechnicien, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function loadTechniciens(){
            Technicien.technicienEnabledHistory({ }, function onSuccess(data, headers) {
                vm.techniciens = data;
                vm.newTech = vm.techniciens[0];
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function addTech() {
            if(vm.chargeTechnicien.techniciens == null){
                vm.chargeTechnicien.techniciens = [];
            }

            //verifie si le technicien a déjà été ajouté
            if (vm.newTech){
                if (vm.chargeTechnicien.techniciens.indexOf(vm.newTech) === -1) {
                    vm.chargeTechnicien.techniciens.push(vm.newTech);
                    adaptCustomFormError();
                }
            }
        }

        function removeTech(technicien) {
            var index = vm.chargeTechnicien.techniciens.indexOf(technicien);
            if (index !== -1) {
                vm.chargeTechnicien.techniciens.splice(index, 1);
                adaptCustomFormError();
            }
        }

        function adaptCustomFormError() {
            if (vm.chargeTechnicien.techniciens){
                vm.customFormError = !(vm.chargeTechnicien.techniciens.length > 0);
            }else {
                vm.customFormError = true;
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
