(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('EquipeCopyModalController', EquipeCopyModalController);

    EquipeCopyModalController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'Equipe','AlertService'];

    function EquipeCopyModalController ($timeout, $scope, $stateParams, $uibModalInstance, Equipe, AlertService) {

        var vm = this;

        vm.date = $stateParams.date;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.date = false;
        vm.selectedDate = null;
        vm.copyToDate = copyToDate;
        vm.copyErrorMsg = "";

        function copyToDate(){
            const formattedDate = moment(new Date(vm.date)).format('YYYY-MM-DD');
            const formattedDateTarget = moment(new Date(vm.selectedDate)).format('YYYY-MM-DD');
            Equipe.copyToDate({
                    date: formattedDate,
                    dateTarget: formattedDateTarget,
                },
                function onSuccess(data) {
                    if (data.length > 0) {
                        showCopyErrorMsg(data);
                    } else {
                        clear();
                    }
                },
                function onError(error) {
                    AlertService.error(error.data.message)
                });
        }

        function showCopyErrorMsg(data) {
            if(data.length > 1) {
                vm.copyErrorMsg = "Echec de la copie car " + data.join(", ") + " ne sont pas disponibles pour le jour selectionné.";
            } else if(data.length === 1){
                vm.copyErrorMsg = "Echec de la copie car " + data.join(", ") + " n'est pas disponible pour le jour selectionné.";
            }

            setTimeout(function() {
                vm.copyErrorMsg = "";
                $scope.$apply();
            }, 10000);
        }

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
