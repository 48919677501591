(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('InterventionDialogController', InterventionDialogController);

    InterventionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Intervention', 'Technicien', 'Point'];

    function InterventionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Intervention, Technicien, Point) {
        $(document).ready(function () {
            $('#field_technicien').select2();
            $('#field_technicien').on('select2:select', function (e) {
                vm.newTech = $('#field_technicien :selected').val();
            });
        });
        var vm = this;

        vm.intervention = entity;
        vm.bonus = {
            point:null,
            qty:1
        };
        vm.customFormError = true
        vm.isSaving = false;
        vm.pointsTMG = [];
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.addTech = addTech;
        vm.removeTech = removeTech;
        vm.addBonus = addBonus;
        vm.removeBonus = removeBonus;

        loadTechniciens();
        loadPoints();
        adaptCustomFormError();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if(vm.intervention.equipes.length <= 2 && vm.intervention.equipes.length > 0){
                vm.intervention.technicien = vm.intervention.equipes[0];
            }
            vm.isSaving = true;
            if (vm.intervention.id !== null) {
                Intervention.update(vm.intervention, onSaveSuccess, onSaveError);
            } else {
                Intervention.save(vm.intervention, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('tmgApp:interventionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function loadTechniciens(){
            Technicien.technicienEnabledHistory({ }, function onSuccess(data, headers) {
                vm.techniciens = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function loadPoints(){
            Point.categoryTMG({ }, function onSuccess(data, headers) {
                vm.pointsTMG = data;
            });
        }

        function addTech() {
            if(vm.intervention.equipes == null){
                vm.intervention.equipes = [];
            }

            //verifie si le technicien a déjà été ajouté
            if (vm.intervention.equipes.indexOf(vm.newTech) === -1) {
                vm.intervention.equipes.push(vm.newTech);
                vm.intervention.tailleEquipe = vm.intervention.equipes.length;
                adaptCustomFormError();
            }
        }

        function addBonus() {
            if (vm.intervention.activities == null){
                vm.intervention.activities = [];
            }

            var newAct = {};
            newAct.code = vm.bonus.point;
            newAct.qty = vm.bonus.qty;

            vm.intervention.activities.push(newAct);
            adaptCustomFormError();
        }

        function adaptCustomFormError() {
            vm.customFormError = !(vm.intervention.tailleEquipe > 0 && vm.intervention.activities && vm.intervention.activities.length > 0);
        }

        function removeTech(technicien) {
            var index = vm.intervention.equipes.indexOf(technicien);
            if (index !== -1) {
                vm.intervention.equipes.splice(index, 1);
                vm.intervention.tailleEquipe = vm.intervention.equipes.length;
                adaptCustomFormError();
            }
        }

        function removeBonus(bonus){
            var index = vm.intervention.activities.indexOf(bonus);
            if (index !== -1) {
                vm.intervention.activities.splice(index, 1);
                adaptCustomFormError();
            }
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.creationDate = false;
        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
