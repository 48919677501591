(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('PointController', PointController);

    PointController.$inject = ['$state', 'Point', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function PointController($state, Point, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.importMultiple = importMultiple;
        vm.export = exportData;
        vm.showDerivedPoints = false;
        vm.filterCategory=pagingParams.category;
        vm.filterActivity=pagingParams.activity;

        vm.categories=[];

        loadAll();

        function loadAll () {
            Point.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                category: vm.filterCategory,
                activity: vm.filterActivity
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.points = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
            Point.categories(function(data){
            	vm.categories=data;
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                category : vm.filterCategory,
                activity: vm.filterActivity
            });
        }

        function importMultiple() {
        	var jsonPoints = prompt("Please enter json", "{}");

        	if (jsonPoints != null) {
        	    var points=JSON.parse(jsonPoints);
        	    Point.multiple(points,function(){
        	    	loadAll();
        	    });
        	}
        }

        function exportData() {
            Point.export(onSuccess);

            function onSuccess(data) {
                delete data.$promise;
                delete data.$resolved;
                download("export.json",JSON.stringify(data));
            }
        }

        function download(filename, text) {
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', filename);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }

        vm.search=transition;
    }
})();
