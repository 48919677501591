(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('ChargeTechnicienDetailController', ChargeTechnicienDetailController);

    ChargeTechnicienDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ChargeTechnicien'];

    function ChargeTechnicienDetailController($scope, $rootScope, $stateParams, previousState, entity, ChargeTechnicien) {
        var vm = this;

        vm.chargeTechnicien = entity;
        vm.previousState = previousState.name;
    }
})();
