(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('ExportPraxedoController', ExportPraxedoController);

    ExportPraxedoController.$inject = ['$state', 'ExportPraxedo', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function ExportPraxedoController($state, ExportPraxedo, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.processMails = processMails;
        vm.resetProcess = resetProcess;
        vm.resetProcessPraxedo = resetProcessPraxedo;
        vm.resetProcessById = resetProcessById;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;

        vm.filterName = pagingParams.name;
        vm.filterSearch = pagingParams.search;
        vm.filterError = pagingParams.error;

        loadAll();

        function loadAll () {
            ExportPraxedo.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                name: vm.filterName,
                search: vm.filterSearch,
                error: vm.filterError,
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.exportPraxedos = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.filterSearch,
                name: vm.filterName,
                error: vm.filterError,
            }, {
                reload: true
            });
        }

        function processMails(){
        	ExportPraxedo.processMails(function(s){
        		transition();
        	}, function(error) {
                AlertService.error(error.data.message);
            });
        }

        function resetProcess(){
        	ExportPraxedo.resetProcess({ name: vm.filterName}, function(s){
        		transition();
        	}, function(error) {
                AlertService.error(error.data.message);
            });
        }

        function resetProcessPraxedo(){
            ExportPraxedo.resetProcessPraxedo({ name: vm.filterName}, function(s){
                transition();
            }, function(error) {
                AlertService.error(error.data.message);
            });
        }
        resetProcessPraxedo

        function resetProcessById(id){
        	ExportPraxedo.resetProcessById({ id: id}, function(s){
        		transition();
        	}, function(error) {
                AlertService.error(error.data.message);
            });
        }


        function search(){
        	vm.transition();
        }
    }
})();
